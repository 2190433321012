import { Pipe, PipeTransform } from '@angular/core';
import { GrouponThresholdType } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'thresholdTypeUnit' })
export class ThresholdTypeUnitPipe implements PipeTransform {

  transform(value: GrouponThresholdType ): string {
    switch (value) {
      case GrouponThresholdType.Amount:
        return 'zł';
      case GrouponThresholdType.Percent:
        return '%';
      default:
        return 'brak';
    }
  }
}