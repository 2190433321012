import { Component, Injector, OnInit } from '@angular/core';
import { ApplicationComponent } from './components/application.Component';
import { UserService } from './services/user.service';
import { UserSession } from './models/UserSession';
import {
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { Location } from '@angular/common';
import { Strings } from './models/Strings';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { ConfigurationService } from './services/configuration.service';
import { getBaseApiUrl } from './app.module';
import { Configuration } from './models/Configuration';
import { environment } from 'src/environments/environment';
import { SelectedPharmacy } from './models/SelectedPharmacy';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './services/auth/oidc/flows.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserService],
})
export class AppComponent extends ApplicationComponent implements OnInit {
  userSession: UserSession;
  selectorContext: boolean;
  navigating = false;
  spinnerDisplayed = false;

  trackerContext = {
    MatomoUrl: environment.MATOMO_URL,
    SiteId: environment.SITE_ID,
    CookieDomain: environment.COOKIE_DOMAIN,
    Domains: environment.DOMAINS,
    GoogleAnalyticsId: environment.GOOGLE_ANALITICS_ID,
    UserId: environment.USER_ID,
    PayerId: environment.PAYER_ID,
    PharmacyId: environment.PHARMACY_ID,
    CompanyId: environment.COMPANY_ID,
    GaEnabled: environment.GA_ENABLED,
    MaEnabled: environment.MA_ENABLED,
    PreviousUrl: environment.PREVIOUS_URL,
    Nu: environment.NU,
  };

  constructor(
    injector: Injector,
    private oauthService: OAuthService,
    private userService: UserService,
    public location: Location,
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker
  ) {
    super(injector);

    this.oauthService.configure(authCodeFlowConfig);

    this.oauthService.loadDiscoveryDocument(environment.oidc.metadataAddress).then(doc => {
      this.oauthService.tryLogin().then(x => console.log('sds'));
    });


    this.matomoInjector.init(
      this.trackerContext.MatomoUrl,
      this.trackerContext.SiteId
    );
    this.router.events.subscribe((evt: Event) => {
      switch (true) {
        case evt instanceof NavigationStart: {
          setTimeout(() => (this.navigating = true));
          break;
        }

        case evt instanceof NavigationEnd: {
          setTimeout(() => {
            this.trackSpaPageView(location.path(false));
            this.navigating = false;
          });
          break;
        }
        case evt instanceof NavigationCancel:
        case evt instanceof NavigationError: {
          setTimeout(() => (this.navigating = false));
          break;
        }

        default:
          break;
      }
    });

    this.spinner
      .getSpinner('primary')
      .subscribe((s) => (this.spinnerDisplayed = s.show));
  }

  ngOnInit() {
    var ccHostW = window as any;
    ccHostW.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#000',
        },
        button: {
          background: '#f1d600',
        },
      },
      theme: 'classic',
      position: 'top',
      content: {
        message:
          'Aplikacja do poprawnego działania (logowanie, sprawdzanie aktywności, zapisywanie ustawień itp.) używa plików cookies. Aby móc korzystać z aplikacji musisz zaakceptować naszą <a href="https://konta.neuca24.pl/Account/TermsOfService#polityka">Politykę prywatności</a>. ',
        dismiss: 'Akceptuję',
        link: 'Co to są cookies?',
        href: 'http://wszystkoociasteczkach.pl',
      },
    });

    this.userSessionService.current.subscribe((u) => {
      this.userSession = u;
      if (u) {
        this.trackerContext.UserId = this.userSession.user.email;
      }
    });

    if (this.trackerContext.GaEnabled) {
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date().valueOf());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        'script',
        '//www.google-analytics.com/analytics.js',
        'ga'
      );

      ga('create', this.trackerContext.GoogleAnalyticsId, 'auto', {
        userId: this.trackerContext.UserId,
        dimension1: this.trackerContext.UserId,
      });
    }

    if (this.trackerContext.MaEnabled) {
      this.matomoTracker.setDocumentTitle(
        document.domain + '/' + document.title
      );
      this.matomoTracker.setCookieDomain(this.trackerContext.CookieDomain);
      this.matomoTracker.setDomains(this.trackerContext.Domains);
      this.matomoTracker.setUserId(this.trackerContext.UserId);
      this.matomoTracker.setCustomDimension(1, this.trackerContext.PayerId);
      this.matomoTracker.setCustomDimension(2, this.trackerContext.PharmacyId);
      this.matomoTracker.setCustomDimension(3, this.trackerContext.CompanyId);
      this.matomoTracker.setCustomDimension(4, this.trackerContext.Nu);
    }
  }

  get hasAdministrationRights(): boolean {
    if (this.userSession && this.userSession.user)
      return this.userSession.user.isRkmh;
    else return false;
  }

  public get isPharmacySelected(): boolean {
    return (
      this.userSessionService &&
      this.userSession &&
      this.userSession.selectedPharmacy &&
      this.userSession.selectedPharmacy.customerId > 0
    );
  }

  logout(): void {
    this.userSessionService.clear();
    this.oauthService.logOut();
  }

  public redirectToNeuca24(): void {
    this.trackEvent(
      'Przekierowano do ' + this.config.AptelinkStarterUrl,
      {},
      {}
    );
    window.open(this.config.AptelinkStarterUrl);
  }

  public trackPageView = function () {
    if (this.trackerContext.GaEnabled) {
      ga('send', 'pageview');
    }

    if (this.trackerContext.MaEnabled) {
      this.matomoTracker.setCustomDimension(1, this.trackerContext.PayerId);
      this.matomoTracker.setCustomDimension(2, this.trackerContext.PharmacyId);
      this.matomoTracker.setCustomDimension(3, this.trackerContext.CompanyId);
      this.matomoTracker.setCustomDimension(4, this.trackerContext.Nu);
      this.matomoTracker.trackPageView();
    }
  };

  public trackSpaPageView = function (pathname) {
    if (this.trackerContext.GaEnabled) {
      ga('set', 'page', pathname);
      ga('set', 'location', window.location.href);
    }

    if (this.trackerContext.MaEnabled) {
      this.matomoTracker.setDocumentTitle(
        document.domain + '/' + document.title
      );

      this.matomoTracker.setGenerationTimeMs(0);
      this.matomoTracker.setCustomUrl(pathname);
      this.matomoTracker.setReferrerUrl(
        this.PreviousUrl == undefined ? document.referrer : this.PreviousUrl
      );
    }
    this.trackPageView();
  };

  public trackEvent = function (category, data, context) {
    context = context === undefined ? {} : context;
    var payerId =
      context.payerId === undefined
        ? this.trackerContext.PayerId
        : context.payerId;
    var pharmacyId =
      context.pharmacyId === undefined
        ? this.trackerContext.PharmacyId
        : context.pharmacyId;
    var companyId =
      context.companyId === undefined
        ? this.trackerContext.CompanyId
        : context.companyId;
    var version = context.version === undefined ? 1 : context.version;

    var action = payerId + ',' + pharmacyId + ',' + this.UserId;
    var obj = {
      category: category,
      payerId: payerId,
      pharmacyId: pharmacyId,
      companyId: companyId,
      userId: this.UserId,
      version: version,
      eventData: data,
    };
    var label = JSON.stringify(obj);
    if (this.GaEnabled) {
      ga('send', 'event', category, action, label);
    }

    if (this.trackerContext.MaEnabled) {
      this.matomoTracker.setCustomDimension(1, this.trackerContext.PayerId);
      this.matomoTracker.setCustomDimension(2, this.trackerContext.PharmacyId);
      this.matomoTracker.setCustomDimension(3, this.trackerContext.CompanyId);
      this.matomoTracker.setCustomDimension(4, this.trackerContext.Nu);
      this.matomoTracker.trackEvent(category, action, label);
    }
  };

  public trackPharmcy = function (params: SelectedPharmacy) {
    if (this.trackerContext.MaEnabled) {
      this.trackerContext.PharmacyId =
        this.trackerContext.PharmacyId == null ||
        this.trackerContext.PharmacyId != params.customerId.toString()
          ? params.customerId.toString()
          : this.trackerContext.PharmacyId;
      this.trackerContext.CompanyId =
        this.trackerContext.CompanyId == null ||
        this.trackerContext.CompanyId != params.salesOrganization.id
          ? params.salesOrganization.id
          : this.trackerContext.CompanyId;
      this.matomoTracker.setCustomDimension(1, this.trackerContext.PayerId);
      this.matomoTracker.setCustomDimension(2, this.trackerContext.PharmacyId);
      this.matomoTracker.setCustomDimension(3, this.trackerContext.CompanyId);
      this.matomoTracker.setCustomDimension(4, this.trackerContext.Nu);
      this.trackPageView();
    }
  };
}
