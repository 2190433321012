import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'sortTranslator'})
export class SortPurchasesTranslator implements PipeTransform {
  transform(value: any): any {

    switch (value) {
        case 'ProductAlphabetically':
            return 'Produkt: alfabetycznie';
        case 'GrouponAlphabetically':
            return 'Grupon: alfabetycznie';
        case 'ProducerAlphabetically':
            return 'Producent: alfabetycznie';
        case 'AscendingDeclarationAmount':
            return 'Kwota wg deklaracji: rosnąco';
        case 'DescendingDeclarationAmount':
            return 'Kwota wg deklaracji: malejąco';
        case 'AscendingRealizationAmount':
            return 'Kwota wg realizacji: rosnąco';
        case 'DescendingRealizationAmount':
            return 'Kwota wg realizacji: malejąco';
        case 'DiscountAscending':
            return 'Rabat: rosnąco';
        case 'DiscountDescending':
            return 'Rabat: malejąco';
        case 'DeclarationDateAscending':
            return 'Data deklaracji: rosnąco';
        case 'DeclarationDateDescending':
            return 'Data deklaracji: malejąco';
        case 'RealizationDateAscending':
            return 'Data realizacji: rosnąco';
        case 'RealizationDateDescending':
            return 'Data realizacji: malejąco';
        case 'GrouponStartDateAscending':
            return 'Data rozpoczęcia gruponu: rosnąco';
        case 'GrouponStartDateDescending':
            return 'Data rozpoczęcia gruponu: malejąco';
        case 'GrouponEndDateAscending':
            return 'Data zakończenia gruponu: rosnąco';
        case 'GrouponEndDateDescending':
            return 'Data zakończenia gruponu: malejąco';
        default:
          return 'Produkt: alfabetycznie';
      }
  }
}
