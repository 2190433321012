import { Injectable, Inject } from '@angular/core';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { ApiHttpClientService } from './api-http-client.service';
import { API_BASE_URL } from './service-proxies/api';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(@Inject(API_BASE_URL) private baseUrl: string,
        protected httpClient: ApiHttpClientService) {

    }

    logOut() {
        window.open(this.baseUrl + '/api/user/logout','_self');
        //window.location.assign(this.baseUrl + '/api/user/logout');
    }

    logIn() {
        window.location.assign(this.baseUrl + '/api/user/login');
    }

    isAuthenticated(): Observable<boolean> {
        return this.httpClient.get<boolean>('/api/user/isauthenticated', { withCredentials: true });
    }

    getUser(): Observable<User> {
        return this.httpClient.get<User>('/api/user', { withCredentials: true });
    }

    ImportRkmh(file: File): Observable<any> {
        let Data = new FormData();

        Data.append('ContentFile', file, file.name);

        return this.httpClient.post('/api/user/UploadCustemerRKMH', Data, { withCredentials: true });
    }
}
