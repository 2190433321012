import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GrouponCard } from '../models/groupon/GrouponCard';
import { ApiHttpClientService } from './api-http-client.service';
import { GrouponDeclaration } from '../models/groupon/GrouponDeclaration';
import { User } from '../models/groupon/User';
import { Groupon } from '../models/groupon/Groupon';
import { HttpHeaders } from '@angular/common/http';
import { GrouponParticipant } from '../models/groupon/GrouponParticipant';
import { GrouponParticipantDto, GrouponNotificationDto, GrouponAttachmentDto, GrouponProductDto, IGrouponBaseDto, GrouponDto, GrouponType, GrouponStatus } from './service-proxies/api';
import { map, tap } from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root'
})

export class GrouponService {
    constructor(
        protected httpClient: ApiHttpClientService) { }

    public UpdateImage(grouponId: number, file: File): Observable<GrouponAttachmentDto> {
        let data = new FormData();

        data.append('ContentFile', file, file.name);

        return this.httpClient.post<FormData>('/api/Groupons/uploadImage?grouponId.value=' + grouponId, data, { withCredentials: true }).pipe(
            map(data => {
                return new GrouponAttachmentDto(data);
            }));
    }

    public UploadNotificationAttachment(grouponId: number, notificationId: number, file: File): Observable<GrouponAttachmentDto> {
        let data = new FormData();

        data.append('ContentFile', file, file.name);

        return this.httpClient.post<FormData>('/api/Groupons/UploadNotificationAttachment?grouponId.value=' + grouponId +
            '&notificationId.value=' + notificationId, data, { withCredentials: true }).pipe(
                map(data => {
                    return new GrouponAttachmentDto(data);
                }));
    }

    UploadParticipants(file: File): Observable<GrouponParticipantDto[]> {
        let Data = new FormData();

        Data.append('ContentFile', file, file.name);

        return this.httpClient.put('/api/Groupons/ImportParticipants', Data, { withCredentials: true }).pipe(
            map(data => {
                return data.map(x => {
                    return new GrouponParticipantDto(x);
                });
            }));
    }

    UploadPositions(file: File): Observable<GrouponProductDto[]> {
        let Data = new FormData();

        Data.append('ContentFile', file, file.name);

        return this.httpClient.put('/api/Groupons/ImportPositions', Data, { withCredentials: true }).pipe(
            map(data => {
                return data.map(x => {
                    return new GrouponProductDto(x);
                }, (error) => {
                    throw error;
                });
            }));
    }

    public sendNotification(grouponId: number, notification: GrouponNotificationDto): Observable<Groupon[]> {
        return this.httpClient.post<FormData>('/api/Groupons/SendNotification?grouponId=' + grouponId,
            notification, { withCredentials: true });
    }

    getGrouponLog(grouponId: number): Observable<any> {
        let blobObservable = this.httpClient.post('/api/Groupons/GrouponLog?grouponId.value=' + grouponId, null,
            { responseType: 'blob', withCredentials: true });
        return blobObservable.pipe(
            tap( // Log the result or error
                () => { },
                error => { throw error; }
            )
        );
    }

    isEditable(elName: string, groupon: GrouponDto) {
        // draft
        if (groupon.status === GrouponStatus.Draft && groupon.statusName !== "Nieaktywna") {
            return true;
        }

        if (groupon.status === GrouponStatus.Draft && groupon.statusName === "Nieaktywna") {
            if (elName === "grouponType") {
                return false;
            }
            return true;
        }

        if (groupon.status === GrouponStatus.DraftLimited && groupon.type === GrouponType.Default) {
            if (
                elName === "grouponName" ||
                elName === "endDate" ||
                elName === "description" ||
                elName === "isHit" ||
                //elName === "products" ||
                elName === "notifications" ||
                elName === "thresholds" ||
                elName === "targetGroup"
            ) {
                return true;
            } else {
                return false;
            }
        } if (groupon.status === GrouponStatus.DraftLimited && (groupon.type === GrouponType.Mixed
            || groupon.type === GrouponType.Package)) {
            if (
                elName === "grouponName" ||
                elName === "endDate" ||
                elName === "description" ||
                elName === "isHit" ||
                //elName === "products" ||
                elName === "notifications" ||
                elName === "targetGroup"
            ) {
                return true;
            } else {
                return false;
            }
        }
        else {
            // if (propertyName === 'notifications') {
            //     return true;
            // }
        }
        return false;
    }

    isActive(elName: string, groupon: GrouponDto) {
        // todo add / edit products
        // always active
        if (elName === "copyTo") {
            return true;
        }
        // in draft
        if (groupon.status === GrouponStatus.Draft) {
            if (
                elName === "removeThreshold" ||
                elName === "addThreshold" ||
                elName === "addImage" ||
                elName === "removeProduct" ||
                elName === "delete" ||
                elName === "importParticipants" ||
                elName === "publishGroupon" ||
                elName === "save" ||
                elName === "addPosition" ||
                elName === "importPositions" ||
                elName === "addNewNotification"
            ) {
                return true;
            }

            if (elName === "addNotificationAttachment") {
                if (groupon.id && groupon.id > 0) {
                    return true;
                }
            }
            return false;
        }
        // todo check if can edit products
        // in draft limited
        if (groupon.status === GrouponStatus.DraftLimited && groupon.type === GrouponType.Default) {
            if (
                elName === "addImage" ||
                elName === "importParticipants" ||
                elName === "save" ||
                elName === "addNewNotification" ||
                elName === "sendAdHocNotification" ||
                elName === "addNotificationAttachment"
                //elName === "removeProduct" ||
                //elName === "addPosition" ||
                //elName === "removeThreshold" ||
                //elName === "addThreshold"
            ) {
                return true;
            }
            return false;
        }
        if (groupon.status === GrouponStatus.DraftLimited && (groupon.type === GrouponType.Mixed
            || groupon.type === GrouponType.Package)) {
            if (
                elName === "addImage" ||
                elName === "importParticipants" ||
                elName === "save" ||
                elName === "addNewNotification" ||
                elName === "sendAdHocNotification" ||
                elName === "addNotificationAttachment"
                //elName === "removeProduct" ||
                //elName === "addPosition"
            ) {
                return true;
            }
            return false;
        }

        // while active/inactive
        if (groupon.status === GrouponStatus.Active || groupon.status === GrouponStatus.Inactive) {
            if (
                elName === "edit" ||
                elName === "shwoLog" ||
                elName === "sendAdHocNotification" ||
                elName === "finish"
            ) {
                return true;
            }
            return false;
        }

        // while archive
        if (groupon.status === GrouponStatus.Archival) {
            if (
                elName === "shwoLog" ||
                elName === "sendAdHocNotification" ||
                elName === "generateReport" ||
                elName === "delete"
            ) {
                return true;
            }
            return false;
        }

        // while finished
        if (groupon.status === GrouponStatus.Finished) {
            if (
                elName === "shwoLog" ||
                elName === "sendAdHocNotification" ||
                elName === "generateReport"
            ) {
                return true;
            }
            return false;
        }

        return true;
    }
}
