import { Component, OnInit, Injector, Input } from '@angular/core';
import { ApplicationComponent } from '../../application.Component';
import { SliderConfigurationDto, API_Dashboard, Orientation } from 'src/app/services/service-proxies/api';


@Component({
  selector: 'slider-configuration',
  templateUrl: './slider-configuration.component.html',
  styleUrls: ['./slider-configuration.component.scss']
})
export class SliderConfigurationComponent extends ApplicationComponent implements OnInit {

  configuration: SliderConfigurationDto;
  private orig_configuration: SliderConfigurationDto;
  Orientation = Orientation;

  constructor(injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  ngOnInit() {
    this.loading(true);
    this.api.getSliderConfiguration().subscribe(c => {
      if (c) {
        this.configuration = c;
        this.configuration.slides.sort((x, y) => x.order - y.order)
        this.orig_configuration = c.clone();
        this.loading(false);
      }
    },
      e => {
        this.loading(false);
        this.notification.error("Nie udało się pobrać konfiguracji banerów.")
      });
  }

  revertChanges() {
    this.yesNoDialog("", "Czy na pewno odrzucić zmiany?")
      .afterClosed()
      .subscribe(a => {
        if (a) {
          this.configuration = this.orig_configuration.clone();
        }
      });
  }

  isValid(): boolean {
    if (this.configuration.slides.some(s =>
      s.enabled && (!s.image.fhd || !s.image.mobile))) {
      return false;
    }

    return true;
  }

  save() {

    if (!this.isValid()) {
      this.errorDialog("Nieprawidłowa konfiguracja", "Wszystkie aktywne(pokazywane na stronie) slajdy muszą posiadać grafikę dla wszystkich rozdzielczości.");
      return;
    }

    this.yesNoDialog("", "Czy na pewno zapisać i opublikować zmiany?")
      .afterClosed()
      .subscribe(a => {
        if (a) {
          this.loading(true);
          this.api.saveSliderConfiguration(this.configuration).subscribe(
            s => {
              this.notification.succes("Pomyślnie zapisano i opublikowano nową konfigurację banerów");
              this.loading(false);
            },
            e => {
              this.notification.error("Nie udało się zapisać konfiuracji banerów.");
              this.loading(false);
            }
          );
        }
      });
  }
}
