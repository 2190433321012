
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private oauthService: OAuthService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>
 {
    if (!this.oauthService.hasValidAccessToken()) {     
      this.oauthService.tryLogin();
    }

    if (!this.oauthService.hasValidAccessToken()) {     
      this.oauthService.initCodeFlow();

      return false;
    }

    return true;
  }

}
