import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'grouponStatusArray'})
export class GrouponStatusArray implements PipeTransform {
  transform(value: any, args: any[]): any {
    let items: any[] = [];
    for (let key in value){
        var isValueProperty = parseInt(key, 10) >= 0;
        if(!isValueProperty) continue;
        if(value[key] != "DraftLimited"){
          items.push({key: key, value: value[key]});
        }else{
          continue;
        }      
    }
    return items;
}
}