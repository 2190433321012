import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, pipe } from 'rxjs';
import { UserSession } from '../models/UserSession';
import { UserService } from './user.service';
import { SelectedPharmacy } from '../models/SelectedPharmacy';
import { API_User, UserDto } from './service-proxies/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private currentSessionSubject: BehaviorSubject<UserSession>;
  public current: Observable<UserSession>;

  constructor(private userService: API_User ) {
    this.currentSessionSubject = new BehaviorSubject<UserSession>(JSON.parse(localStorage.getItem('userSession')));
    this.current = this.currentSessionSubject.asObservable();
  }

  public get currentValue() {
    return this.currentSessionSubject.value;
  }

  async set(pharmacy: SelectedPharmacy) : Promise<UserSession> {
    let s = await this.userService.get().toPromise().then(u => {
      if (u) {
        let session = new UserSession();
        session.user = u;
        session.selectedPharmacy = pharmacy;

        localStorage.setItem('userSession', JSON.stringify(session));
        this.currentSessionSubject.next(session);
        return session;
      };
      
    });
    return s;
  }

  clear() {  
    //this.currentSessionSubject.next(null);
    localStorage.removeItem('userSession');
  }
}
