import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'grouponSortTranslator'})
export class SortGrouponsTranslator implements PipeTransform {
  transform(value: any): any {

    switch (value) {
        case 'GrouponAlphabetically':
            return 'Grupon: alfabetycznie';
        case 'DaysLeft':
            return 'Pozostało dni: malejąco';
        case 'GrouponStartDateAscending':
            return 'Data rozpoczęcia gruponu: rosnąco';
        case 'GrouponStartDateDescending':
            return 'Data rozpoczęcia gruponu: malejąco';
        case 'GrouponEndDateAscending':
            return 'Data zakończenia gruponu: rosnąco';
        case 'GrouponEndDateDescending':
            return 'Data zakończenia gruponu: malejąco';
        default:
          return 'Data rozpoczęcia gruponu: malejąco';
      }
  }
}
