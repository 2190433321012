import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { Component, OnInit, Optional, Host, Input } from '@angular/core';


@Component({
  selector: 'app-inline-number-edit',
  templateUrl: './inline-number-edit.component.html',
  styleUrls: ['./inline-number-edit.component.scss']
})
export class InlineNumberEditComponent implements OnInit {

  constructor(@Optional() @Host() public popover: SatPopover) {
  }  

  @Input()
  get value(): number { return this._value; }
  set value(x: number) {
    this.val = this._value = x;
  }

  @Input()
  title: string;

  @Input()
  minValue: number;

  @Input()
  maxValue: number;

  @Input()
  hint: string;

  @Input()
  suffix: string

  @Input()
  suffixIcon: string

  @Input()
  prefix: string;

  private _value: number = 0;
  val = 0;

  savedWHValue: number = null;
  ngOnInit() {
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
        .subscribe(() => this.val = this.value || 0);
    }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.val);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }

  get min() {
    if (this.minValue != undefined)
      return this.minValue
    else
      return -Infinity.valueOf();
  }

  get max() {
    if (this.maxValue != undefined)
      return this.maxValue
    else
      return Infinity.valueOf();
  }
}
