export class InputDialogData {
  title: string;
  description: string;
  input: any;
  inputType: string;
  placeholder: string;

  constructor(title: string, description: string, inputType: string) {
      this.title = title;
      this.description = description;
      this.inputType = inputType;
  }
}