import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FlatNode } from 'src/app/models/tree/flat-node';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { transformer, hasChild } from 'src/app/models/tree/functions';
import { API_Dashboard, AllActiveActionsStatisticsDto, StatisticsActionDto } from 'src/app/services/service-proxies/api';
import { Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-all-active-actions',
  templateUrl: './all-active-actions.component.html',
  styleUrls: ['./all-active-actions.component.scss']
})
export class AllActiveActionsComponent extends ApplicationComponent implements OnInit {

  stats$: Observable<AllActiveActionsStatisticsDto>;
  showActionsList = false;

  constructor(injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  ngOnInit() {

    if (!this.userSessionService.currentValue || !this.userSessionService.currentValue.selectedPharmacy) {
      return;
    }

    this.loadingLocal(true, 'allActiveActionsSpinner');
    this.stats$ = this.api.getAllActiveActions(this.userSessionService.currentValue.selectedPharmacy.customerId)
      .pipe(tap(x=> this.loadingLocal(false, 'allActiveActionsSpinner')));
   
  } 
  navigateToDeclaration(action: StatisticsActionDto) {
    this.router.navigate(['/', 'grouponDeclaration', action.grouponId]);
  }

  showGroupons(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "filter": "active"
      }
  };
  this.router.navigate(["groupons"], navigationExtras);
  }
}
