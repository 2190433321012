import { NgModule } from '@angular/core';
import { SortYourPurchasesPipe } from './components/shared/transform-pipes/sort-YourPurchases';
import { FilterYourPurchasesPipe } from './components/shared/transform-pipes/filter-YourPurchases';
import { FilterPipe } from './components/shared/transform-pipes/filter-pipe';
import { SortByPipe } from './components/shared/transform-pipes/sort-by-pipe';
import { IterablePipe } from './components/shared/transform-pipes/groupons-filter-pipe';
import { SettlementingUnitPipe } from './components/shared/transform-pipes/settlementing-unit-pipe';
import { ThresholdTypeUnitPipe } from './components/shared/transform-pipes/thresholdType-unit-pipe';
import { OrderStatusTranslator } from './components/shared/transform-pipes/translate-order-status';
import { SortPurchasesTranslator } from './components/shared/transform-pipes/translate-sort-yourPurchases';
import { FilterPurchasesTranslator } from './components/shared/transform-pipes/translate-yourPurchases';
import { GrouponFilterTranslator } from './components/shared/transform-pipes/groupon-filter-translator-pipe';
import { GrouponTypeTranslate } from './components/shared/transform-pipes/groupon-type-translate-pipe';
import { GrouponSettlementingTypeTranslate } from './components/shared/transform-pipes/groupon-settlementingType-translate-pipe';
import { RealizationStatusTranslate } from './components/shared/transform-pipes/realizationStatus-translate-pipe';
import { FilterRkmhPanelName } from './components/shared/transform-pipes/filter-rkmh-panel';
import { GrouponStatusArray } from './components/shared/transform-pipes/groupon-status-pipe';
import { GrouponStatusTranslate } from './components/shared/transform-pipes/groupon-status-translate';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { EllipsisPipe } from './components/shared/transform-pipes/ellipsis-pipe';
import { GrouponThresholdTypeTranslate } from './components/shared/transform-pipes/threshold-type-translate-pipe';
import { SortYourGrouponsPipe } from './components/shared/transform-pipes/sort-YourGroupons';
import { SortGrouponsTranslator } from './components/shared/transform-pipes/translate-sort-yourGroupons';


@NgModule({
  declarations: [
    SortYourPurchasesPipe,
    SortYourGrouponsPipe,
    FilterYourPurchasesPipe,
    FilterPipe,
    SortByPipe,
    SettlementingUnitPipe,
    ThresholdTypeUnitPipe,
    IterablePipe,
    OrderStatusTranslator,
    SortPurchasesTranslator,
    SortGrouponsTranslator,
    FilterPurchasesTranslator,
    GrouponFilterTranslator,
    GrouponTypeTranslate,
    GrouponThresholdTypeTranslate,
    GrouponSettlementingTypeTranslate,
    RealizationStatusTranslate,
    FilterRkmhPanelName,
    GrouponStatusArray,
    GrouponStatusTranslate,
    EllipsisPipe
  ],
  imports: [
    FilterPipeModule
  ],
  exports: [
    SortYourPurchasesPipe,
    SortYourGrouponsPipe,
    FilterYourPurchasesPipe,
    FilterPipe,
    SortByPipe,
    SettlementingUnitPipe,
    ThresholdTypeUnitPipe,
    IterablePipe,
    OrderStatusTranslator,
    SortPurchasesTranslator,
    SortGrouponsTranslator,
    FilterPurchasesTranslator,
    GrouponFilterTranslator,
    GrouponTypeTranslate,
    GrouponThresholdTypeTranslate,
    GrouponSettlementingTypeTranslate,
    RealizationStatusTranslate,
    FilterRkmhPanelName,
    GrouponStatusArray,
    GrouponStatusTranslate,
    FilterPipeModule,
    EllipsisPipe
  ]
})

export class PipesModule { }