import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], column: string = '', order = []): any[] {
    if (!value || order === [] || !order) { return value; }
    if (!column || column === '') { return _.sortBy(value); }
    if (value.length <= 1) { return value; }
    return _.orderBy(value, [column], order || 'asc');
  }
}