import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogData } from 'src/app/models/dialogs/input-dialog-data';

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.css']
})
export class SingleInputDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SingleInputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: InputDialogData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
