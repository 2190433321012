import { Injector, OnDestroy, AfterViewInit, OnInit, ChangeDetectorRef, Output, EventEmitter, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SnackBarService } from '../services/snack-bar.service';
import { YesNoDialogComponent } from './shared/dialogs/yes-no-dialog/yes-no-dialog.component';
import { ErrorDialogComponent } from './shared/dialogs/error-dialog/error-dialog.component';
import { ConfigurationService } from '../services/configuration.service';
import { Configuration } from '../models/Configuration';
import { NgxSpinnerService } from "ngx-spinner";
import { UserSessionService } from '../services/user-session-service.service';
import { saveAs } from 'file-saver';
import { SCREEN_SIZE } from '../models/screen-size';
import { ResizeService } from '../services/resize.service';
import { Location } from '@angular/common';
import { PreviousUrlService } from '../services/previous-url.service';
import { Size, Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { InfoDialogComponent } from './shared/dialogs/info-dialog/info-dialog.component';


@Directive()
export class ApplicationComponent implements AfterViewInit {

    public router: Router;
    public httpClient: HttpClient;
    public isLoading: boolean = false;
    public notification: SnackBarService;
    public dialog: MatDialog;
    public configurationService: ConfigurationService;
    public spinner: NgxSpinnerService;
    public userSessionService: UserSessionService;
    public screenSizeService: ResizeService;
    public activatedRoute: ActivatedRoute;
    public location: Location;
    public previousUrlSerice: PreviousUrlService;
    protected cd: ChangeDetectorRef

    size: SCREEN_SIZE;
    SCREEN_SIZE = SCREEN_SIZE;
    @Output() events: EventEmitter<any> = new EventEmitter();


    constructor(injector: Injector) {
        this.router = injector.get<Router>(Router);
        this.httpClient = injector.get<HttpClient>(HttpClient);
        this.notification = injector.get<SnackBarService>(SnackBarService);
        this.dialog = injector.get<MatDialog>(MatDialog);
        this.configurationService = injector.get<ConfigurationService>(ConfigurationService);
        this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
        this.userSessionService = injector.get<UserSessionService>(UserSessionService);
        this.screenSizeService = injector.get<ResizeService>(ResizeService);
        this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
        this.location = injector.get<Location>(Location);
        this.previousUrlSerice = injector.get<PreviousUrlService>(PreviousUrlService);
        this.cd = injector.get(ChangeDetectorRef);
    }

    ngAfterViewInit() {
        this.screenSizeService.onResize$
            .subscribe(x => {
                setTimeout(() => this.size = x);
            });
    }

    protected get config(): Configuration {
        return this.configurationService.getConfig();
    }

    protected imagePath(serverRelativeFilePath: string) {
        const cfg = this.configurationService.getConfig();
        return `${cfg.BackendServiceApiUrl}${cfg.BackendServiceApiUrl.endsWith('/') ? '' : '/'}${serverRelativeFilePath}`;
    }

    protected loading(loadingState: boolean, spinnerName?: string, fullScreen?: boolean, size?: Size, color?: string, bgColor?: string) {
        setTimeout(() => {
            this.isLoading = loadingState;
            this.events.emit(new Event(""))
            if (loadingState) {
                return this.spinner.show(spinnerName,
                    {
                        type: 'ball-atom',
                        size: size || 'medium',
                        fullScreen: fullScreen || true,
                        color: color || '#fff',
                        bdColor: bgColor || 'rgba(51,51,51,0.8)'
                    });
            }
            else {
                return this.spinner.hide(spinnerName);
            }
        });
    }

    protected loadingLocal(loadingState: boolean, spinnerName?: string, spinner?: Spinner) {
        setTimeout(() => {
            this.isLoading = loadingState;

            if (spinnerName) {
                if (loadingState) {
                    return this.spinner.show(spinnerName, spinner || this.getLocalSpinner());
                } else {
                    return this.spinner.hide(spinnerName);
                }
            }
        });
    }

    private getLocalSpinner(): Spinner {
        return {
            type: 'ball-atom',
            size: 'default',
            fullScreen: false,
            color: 'grey',
            bdColor: '#fff'
        };
    }

    protected yesNoDialog(title: string, question: string, yesTooltip?: string, noTooltip?: string): MatDialogRef<YesNoDialogComponent> {
        return this.dialog.open(YesNoDialogComponent, {
            data: {
                title: title, question: question,
                yesHint: yesTooltip, noHint: noTooltip
            }
        });
    }

    protected yesNoDialogAnswer(title: string, question: string, yesTooltip?: string, noTooltip?: string): Observable<boolean> {
        return this.yesNoDialog(title, question, yesTooltip, noTooltip).afterClosed();
    }

    protected infoDialog(title: string, message: string): MatDialogRef<InfoDialogComponent> {
        return this.dialog.open(InfoDialogComponent, {
            data: { title: title, message: message }
        });
    }

    protected errorDialog(title: string, message: string): MatDialogRef<ErrorDialogComponent> {
        return this.dialog.open(ErrorDialogComponent, {
            data: { title: title, errorMessage: message }
        });
    }

    protected errorsDialog(title: string, message: string, details: Array<string>): MatDialogRef<ErrorDialogComponent> {
        return this.dialog.open(ErrorDialogComponent, {
            data: { title: title, errorMessage: message, errorMessages: details }
        });
    }

    protected OpenFileFromStream(data: any, fileType: string, filename: string) {
        const blob = new Blob([data], { type: fileType });
        saveAs(blob, filename);
    }
}
