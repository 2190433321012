import { Pipe, PipeTransform } from '@angular/core';
import { GrouponSettlementingType } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'grouponSettlementingTypeTranslate' })
export class GrouponSettlementingTypeTranslate implements PipeTransform {

  transform(value: GrouponSettlementingType): string {
    switch (value) {
      case GrouponSettlementingType.Count:
        return 'ilościowy';
      case GrouponSettlementingType.Value:
        return 'wartościowy';
    }
  }
}