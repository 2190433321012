import { Component, OnInit, Injector, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { API_Dashboard, UserActiveActionsStatisticsDto, StatisticsActionDto } from 'src/app/services/service-proxies/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from "moment";
import { NavigationExtras } from '@angular/router';
import { CountdownConfig, CountdownComponent, CountdownFormatFn } from 'ngx-countdown';


@Component({
  selector: 'app-user-active-actions',
  templateUrl: './user-active-actions.component.html',
  styleUrls: ['./user-active-actions.component.scss']
})
export class UserActiveActionsComponent extends ApplicationComponent implements OnInit, AfterViewInit {

  stats$: Observable<UserActiveActionsStatisticsDto>;
  statsFromOnlyActiveStatus$: Observable<UserActiveActionsStatisticsDto>;
  showActionsList = false;

  showActionActive = false;
  today = moment(new Date()).locale("PL");
  daysToFinishAction: any = new DaysFormat;
  grouponActiveName: string = "";
  grouponActiveId: number = 0;
  actionsConut: number;
  duration = 0;

  constructor(injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  ngOnInit() {
    if (!this.userSessionService.currentValue || !this.userSessionService.currentValue.selectedPharmacy) {
      return;
    }

    this.loadingLocal(true, 'userActiveActionsSpinner')

    this.statsFromOnlyActiveStatus$ = this.api.getUserActiveActionsOnlyInStatusActive(this.userSessionService.currentValue.selectedPharmacy.customerId)
      .pipe(tap(() => this.loadingLocal(false, 'userActiveActionsSpinner')));

    this.statsFromOnlyActiveStatus$.subscribe(x => {
      if (x != null && x.actions.length > 0) {
        this.calculateDate(x.actions[0].endDate);
        this.showActionsList = true;
        this.grouponActiveName = x.actions[0].name;
        this.grouponActiveId = x.actions[0].grouponId;
        this.actionsConut = x.actions.length;
      } else {
        this.actionsConut = 0;
      }
    });
    this.showActionActive = true;
  }

  navigateToDeclaration(action: StatisticsActionDto) {
    this.router.navigate(['/', 'grouponDeclaration', action.grouponId]);
  }

  calculateDate(grouponDate: moment.Moment): DaysFormat {
    var grouponDateMoment = moment(new Date(grouponDate.toDate().setHours(23, 59, 59))).locale("PL")
    var date = grouponDateMoment.toDate().getTime() - this.today.toDate().getTime();

    let left = moment.duration(grouponDateMoment.diff(this.today)).add(-1, 'day').asSeconds();
    this.timerConfig.leftTime = left > 0 ? left : 0;

    var delta = Math.abs(date) / 1000;

    var days1 = Math.floor(delta / 86400);
    delta -= days1 * 86400;

    // calculate (and subtract) whole hours
    var hours1 = Math.floor(delta / 3600) % 24;
    delta -= hours1 * 3600;

    // calculate (and subtract) whole minutes
    var minutes1 = Math.floor(delta / 60) % 60;
    delta -= minutes1 * 60;

    // what's left is seconds
    var seconds1 = delta % 60;

    this.daysToFinishAction.totalSeconds = date;
    this.daysToFinishAction.days = days1;
    this.daysToFinishAction.hours = hours1;
    this.daysToFinishAction.minutes = minutes1;
    this.daysToFinishAction.seconds = seconds1.toFixed(0);
    return this.daysToFinishAction
  }

  showGroupons() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "filter": "participate"
      }
    };
    this.router.navigate(["groupons"], navigationExtras);
  }


  timerConfig: CountdownConfig = {
    leftTime: 0,
    format: 'dd:H:mm:ss',
    demand: false,
    prettyText: (text) => {
      var parts = text.split(":");
      return `
      <span class="mx-1">
        <span class="main">${this.timerConfig.leftTime == 0 ? 0 : parts[0]}</span><span class="secondary">dni</span>
                    </span>
                    <span class="mx-1 countdown-text ">
                        <span class="main">${parts[1]}</span><span class="secondary">godzin</span>
                    </span>
                    <span class="mx-1">
                        <span class="main">${parts[2]}</span><span class="secondary">minut</span>
                    </span>
                    <span class="mx-1">
                        <span class="main">${parts[3]}</span><span class="secondary">sekund</span>
                    </span>`;
    },
  };
}

export class DaysFormat {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
}
