import { Pipe, PipeTransform } from '@angular/core';
import { GrouponThresholdType } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'grouponThresholdTypeTranslate' })
export class GrouponThresholdTypeTranslate implements PipeTransform {

  transform(value: GrouponThresholdType): string {
    switch (value) {
      case GrouponThresholdType.Amount:
        return 'kwotowy';
        case GrouponThresholdType.Percent:
        return 'procentowy';
    }
  }
}