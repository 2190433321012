import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { ApplicationComponent } from "../../application.Component";
import { GrouponService } from "../../../services/groupon.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { User } from "src/app/models/groupon/User";
import { Strings } from "src/app/models/Strings";
import { FormControl, NgForm } from "@angular/forms";
import {
  API_Materials,
  GrouponProductDto,
  GrouponThresholdDto,
  API_Groupons,
  API_User,
  GrouponDto,
  UpdateGrouponDto,
  CreateGrouponDto,
  GrouponParticipantDto,
  GrouponNotificationDto,
  UserDto,
  GrouponRealizationStatus,
  SetRealizationStatusDto,
  GrouponStatus,
  NotificationType,
  NotificationReceiverType,
  ProducerDto,
  API_Producers,
  GrouponSettlementingType,
  GrouponThresholdType,
  GrouponType,
  FileResponse,
  GrouponTargetGroup
} from "src/app/services/service-proxies/api";
import { of } from "rxjs";
import * as moment from "moment";
import { APP_ROUTES } from '../../APP_ROUTES';

@Component({
  selector: "app-groupons",
  templateUrl: "./groupon-editor.component.html",
  styleUrls: ["./groupon-editor.component.scss"]
})
export class GrouponEditorComponent extends ApplicationComponent
  implements OnInit {
  @ViewChild("mainSort", { static: true }) sort: MatSort;
  @ViewChild("fileInput", { static: true }) fileInput;
  @ViewChild("participantsPaginator", { static: false }) participantsPaginator: MatPaginator;
  @ViewChild("positionsPaginator", { static: false }) positionsPaginator: MatPaginator;
  @ViewChild("editorForm", { static: false }) form: NgForm;

  productSearchText: string;
  minStartDate = moment(new Date()).locale("PL");
  minEndDate = moment(new Date(this.minStartDate.toDate())).locale("PL")


  groupon: GrouponDto = new GrouponDto();
  private origGroupon: GrouponDto = new GrouponDto();
  users: UserDto[];
  currentOwner: UserDto;
  positionsDataSource: MatTableDataSource<GrouponProductDto> = new MatTableDataSource();
  participantsDataSource: MatTableDataSource<GrouponParticipantDto> = new MatTableDataSource();
  positionsDataSourceColumns = [
    "lineNumber",
    "materialId",
    "bloz",
    "name",
    "multiplicity",
    "Actions"
  ];
  displayedParticipantsColumns = ["PharmacyId", "Name"];
  materialsSearchControl = new FormControl();
  producersSearchControl = new FormControl();
  availableMaterials: GrouponProductDto[];
  availableProducers: ProducerDto[];

  imortParticipantsFile: File;

  importPositionsFile: File;

  isReadonly: boolean;
  newImage: File;
  newImagePreview: any = null;
  previewUrl: any = null;
  maxFileSize = 29360128; // 28MB

  Realization = GrouponRealizationStatus;
  Status = GrouponStatus;
  NotificationReceiverType = NotificationReceiverType;
  SettlementingType = GrouponSettlementingType;
  ThresholdType = GrouponThresholdType;
  Type = GrouponType;
  TargetGroup = GrouponTargetGroup;

  selectedTab = new FormControl(0);

  constructor(
    injector: Injector,
    private grouponService: GrouponService,
    private api_Materials: API_Materials,
    private api_Groupons: API_Groupons,
    private api_User: API_User,
    private api_Producers: API_Producers,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);

    this.screenSizeService.onResize$.subscribe(x => {
      setTimeout(() => (this.size = x));
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(p => {
      const grouponId = parseInt(p.Id);
      if (grouponId && grouponId > 0) {
        this.loadGroupon(grouponId);
      } else if (grouponId === 0) {
        this.init();
        this.edit();
      }
    });

    this.loadUsers();
    this.hotReloadMaterials();
    this.hotReloadProducers();
    this.isReadonly = false;
  }

  init() {
    let groupon = new GrouponDto();
    groupon.type = GrouponType.Default;
    groupon.statusName = "Szkic (niezapisany)";
    groupon.settlementingType = GrouponSettlementingType.Count;
    groupon.thresholdType = GrouponThresholdType.Percent;
    groupon.targetGroup = GrouponTargetGroup.PerGroupOfCustomers;
    this.initNotifications(groupon);

    this.setGroupon(groupon);
  }

  initNotifications(groupon: GrouponDto) {
    groupon.notifications = new Array<GrouponNotificationDto>();
    groupon.notifications.push(
      new GrouponNotificationDto({
        id: 0,
        message: "",
        daysBeforeEnd: undefined,
        attachmentPath: undefined,
        attachmentType: undefined,
        type: NotificationType.AtStart,
        receiverType: NotificationReceiverType.All,
        isActive: false
      })
    );
    groupon.notifications.push(
      new GrouponNotificationDto({
        id: 0,
        message: "",
        daysBeforeEnd: 3,
        attachmentPath: undefined,
        attachmentType: undefined,
        type: NotificationType.BeforeEnd,
        receiverType: NotificationReceiverType.All,
        isActive: false
      })
    );
    groupon.notifications.push(
      new GrouponNotificationDto({
        id: 0,
        message: "",
        daysBeforeEnd: undefined,
        attachmentPath: undefined,
        attachmentType: undefined,
        type: NotificationType.AdHoc,
        receiverType: NotificationReceiverType.All,
        isActive: false
      })
    );
  }

  hotReloadMaterials() {
    this.materialsSearchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.availableMaterials = [];
          this.loadingLocal(true);
        }),
        switchMap((value: string) =>
          value
            ? this.api_Materials
              .get(
                value,
                (this.groupon.producer && this.groupon.producer.id) || " "
              )
              .pipe(
                finalize(() => {
                  this.loadingLocal(false);
                })
              )
            : of([]).pipe(
              finalize(() => {
                this.loadingLocal(false);
              })
            )
        )
      )
      .subscribe(data => {
        if (!data) {
          this.availableMaterials = [];
        } else {
          this.availableMaterials = <GrouponProductDto[]>data;
        }
      });
  }

  hotReloadProducers() {
    this.producersSearchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.availableProducers = [];
          this.loadingLocal(true);
        }),
        switchMap((value: string) =>
          value
            ? this.api_Producers.get(value).pipe(
              finalize(() => {
                this.loadingLocal(false);
              })
            )
            : of([]).pipe(
              finalize(() => {
                this.loadingLocal(false);
              })
            )
        )
      )
      .subscribe(data => {
        if (!data) {
          this.availableProducers = [];
        } else {
          this.availableProducers = data;
        }
      });
  }

  displayFn() {
    return "";
  }

  displayFnProducer(pr) {
    if (pr) {
      return `${pr.name} (${pr.id})`;
    }
  }

  changeProducer(producer: ProducerDto) {
    this.groupon.producer = producer;
    this.isReadonly = true;
  }

  addPosition(material: GrouponProductDto) {

    let producerIsCorrect: boolean = false;

    if (!this.groupon.products) {
      this.groupon.products = new Array<GrouponProductDto>();
      this.groupon.producer = new ProducerDto({
        id: material.producerId,
        name: material.producer
      });
      this.isReadonly = true;
    } else {
      this.groupon.products.forEach(x => {
        if (this.groupon.producer.name == x.producer) {
          producerIsCorrect = true;
        }
      })
    }

    if (
      !this.groupon.products.some(p => p.materialId === material.materialId) &&
      (producerIsCorrect || this.groupon.products.length === 0)
    ) {
      let newProduct = new GrouponProductDto();
      newProduct.bloz = material.bloz;
      newProduct.name = material.name;
      newProduct.producer = material.producer;
      newProduct.producerId = material.producerId;
      newProduct.price =0;
        newProduct.multiplicity = 0,
        newProduct.materialId = material.materialId;
      newProduct.lineNumber = this.getNextLineNumber();
      this.groupon.products.push(newProduct);
      this.positionsDataSource = new MatTableDataSource(this.groupon.products);
      this.positionsDataSource.sort = this.sort;
      this.positionsDataSource.paginator = this.positionsPaginator;

      if (!this.groupon.producer) {
        this.groupon.producer = new ProducerDto({
          id: material.producerId,
          name: material.producer
        });
        this.isReadonly = true;
      }
    } else {
      this.notification.warn(
        "Wybrany materiał znajduje się już na liście pozycji gruponu lub podano materiał pochodzący od innego producenta."
      );
    }
  }

  private getNextLineNumber(): number {
    if (this.groupon.type == GrouponType.Package)
      return 1;
    else
      return this.groupon.products.length > 0
        ? Math.max(...this.groupon.products.map(p => p.lineNumber)) + 1
        : 1;
  }

  private setGroupon(groupon: GrouponDto) {
    this.groupon = groupon;

    this.groupon.startDate = moment.utc(this.groupon.startDate)

    this.groupon.endDate = moment.utc(this.groupon.endDate)

    this.currentOwner = this.groupon.owner;
    if (this.currentOwner) {
      this.currentOwner.isActive = true;
    }
    this.positionsDataSource = new MatTableDataSource(this.groupon.products);
    this.positionsDataSource.sort = this.sort;
    this.participantsDataSource = new MatTableDataSource(
      this.groupon.participants
    );
    this.participantsDataSource.paginator = this.participantsPaginator;
    this.positionsDataSource.sort.sort({ id: "lineNumber", start: "asc", disableClear: false } as MatSortable)

    if (!this.groupon.notifications || this.groupon.notifications.length < 1) {
      this.initNotifications(this.groupon);
    }
  }

  loadGroupon(grouponId: number) {
    this.loading(true);

    this.api_Groupons.get(grouponId).subscribe((data: GrouponDto[]) => {
      if (data.length > 0) {
        this.setGroupon(data[0]);
      }
      this.loading(false);
    }, () => {
      this.loading(false);
      this.notification.error('Nie udało się pobrać informacji o gruponie.')
    }
    );
  }

  loadUsers() {
    this.api_User.getAll().subscribe(users => (this.users = users));
  }

  private isValidByThresholds() {

    if (this.groupon.type == GrouponType.Default) {
      return true;
    }

    let lookup = {};
    let result = [];

    if (this.groupon.thresholds !== undefined && this.groupon.thresholds !== null) {
      for (let i = 0; i < this.groupon.thresholds.length; i++) {
        this.groupon.thresholds[i]["key"] = this.groupon.thresholds[i].discountPercent + this.groupon.thresholds[i].discountThreshold;
        if (!lookup[this.groupon.thresholds[i]["key"]]) {
          lookup[this.groupon.thresholds[i]["key"]] = true;
          result.push(this.groupon.thresholds[i]);
        }
      }
      return this.groupon.thresholds.length == result.length;
    } else {
      return false;
    }
  }

  private validate(): string[] {
    let errors = [];

    if (this.groupon.products) {
      if (this.groupon.products.length < 1) {
        errors.push("brak pozycji produktów");
      }
      if (this.groupon.products.some(x => x.producer !== this.groupon.producer.name)) {
        errors.push("niepoprawny producent");
      }

    }
    else {
      errors.push("brak pozycji produktów");
    }

    if (this.groupon.participants === undefined || this.groupon.participants.length < 1) {
      errors.push("brak uczestników");
    }

    //validation for default groupon type
    if (this.groupon.type == GrouponType.Default) {

      if (this.groupon.products) {

        if (this.groupon.products.some(x => x.multiplicity <= 0)) {
          errors.push("nie określono poprawnej(większej od zera) minimalnej ilości dla wszystkich pozycji produktów")
        }

        if (this.groupon.products.some(x => !x.thresholds || x.thresholds.length < 1)) {
          errors.push("brak progów rabatowych rabatowych dla wszystkich pozycji produktów")
        }

        if (this.groupon.products.some(x => x.thresholds && x.thresholds.some(t => t.discountThreshold <= 0))) {
          errors.push("nieprawidłowe wartości w prograch rabatowych dla pozycji produktów");
        }
      }
    }
    else if (this.groupon.type == GrouponType.Mixed || this.groupon.type == GrouponType.Package) {

      if (this.groupon.products) {
        if (this.groupon.products.some(x => x.multiplicity <= 0)) {
          errors.push("nie określono poprawnej(większej od zera) minimalnej ilości dla wszystkich pozycji produktów")
        }
      }

      if (!this.groupon.thresholds || this.groupon.thresholds.length <= 0) {
        errors.push("brak progów rabatowych dla całego gruponu");
      }
      else if (this.groupon.thresholds.some(x => x.discountThreshold <= 0)) {
        errors.push("nieprawidłowe wartości w prograch rabatowych dla gruponów");
      }
    }

    if (!this.isValidByThresholds()) {
      errors.push('nieprawidłowo zdefiniowane progi rabatowe - progi rabatowe nie mogą być identyczne');
    }
    return errors;
  }

  private validateSave(): string[] {
    let errors = this.validate();

    if (this.form.invalid) {
      errors.unshift("uzupełnij wymagane pola");
    }
    return errors;
  }

  save() {
    let errors = this.validateSave();

    if (errors.length > 0) {
      this.errorsDialog("Nie można zapisać gruponu", "Grupon nie jest poprawnie zdefiniowany. Szczegóły poniżej:", errors)
      return;
    }

   

    if (this.groupon.status === GrouponStatus.DraftLimited) {
      let today = moment();

      if (this.groupon.endDate.date >= today.date) {
        this.groupon.status = GrouponStatus.Active;
      } else {
        this.groupon.status = GrouponStatus.Finished;
      }
    }
   

    if (this.groupon.id && this.groupon.id > 0) {
      this.updateGrouponInner();
    } else {
      this.createGrouponInner();
    }
    

  }

  edit() {
    if (this.groupon.status === 2) {
      this.groupon.status = 3;

    } else {
      this.groupon.status = 0;
    }
  }

  updateGrouponInner() {
    this.yesNoDialog("Uwaga", "Czy na pewno chcesz zapisać informacje o gruponie?")
      .afterClosed().subscribe(yes => {
        if (yes) {
          this.loading(true);
          this.groupon.startDate = moment(this.groupon.startDate)

          this.groupon.endDate = moment(this.groupon.endDate.hour(21).minute(59).second(59))

          const request = new UpdateGrouponDto(this.groupon);
          this.api_Groupons.put(this.groupon.id, request).subscribe(
            () => {
              this.loading(false);
              if (this.groupon.products.length > 0) {
                this.isReadonly = true;
              }
              this.notification.succes(
                `Pomyślnie zaktualizowano grupon ${this.groupon.name}.`
              );

              this.loadGroupon(this.groupon.id)

            },
            () => {
              this.loading(false);
              this.notification.error(
                "Nie udało się zaktualizować gruponu."
              );
            }
          );
        } else {
          return;
        }
      });
  }
  createGrouponInner() {
    this.yesNoDialog("Uwaga", "Czy na pewno chcesz utworzyć nowy grupon?")
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);

          this.groupon.startDate = moment(this.groupon.startDate)

          this.groupon.endDate = moment(this.groupon.endDate.hour(21).minute(59).second(59))
         
          const request = new CreateGrouponDto(this.groupon);
          this.api_Groupons.create(request).subscribe(
            created => {
              this.setGroupon(created);
              if (this.newImage) {
                this.saveImage(this.newImage);
              }
              const url = this.router
                .createUrlTree(["/", "grouponEditor", created.id])
                .toString();
              this.location.go(url);

              this.loading(false);
              this.notification.succes(
                `Pomyślnie utworzono grupon ${this.groupon.name}.`
              );
            },
            () => {
              this.loading(false);
              this.notification.error("Nie udało się utworzyć gruponu.");
            }
          );
        }
      });
  }

  publishGroupon() {
    let errors = this.validate();

    if (errors.length > 0) {
      this.errorsDialog("Nie można opublikować gruponu", "Grupon nie jest poprawnie zdefiniowany. Szczegóły poniżej:", errors)
      return;
    }

    this.yesNoDialog("Uwaga!", "Czy na pewno chcesz opublikować grupon?")
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);

          this.groupon.startDate = moment(this.groupon.startDate);
          this.groupon.endDate = moment(this.groupon.endDate);
          this.api_Groupons.publish(this.groupon.id).subscribe(
            (published: GrouponDto) => {
              this.setGroupon(published);
              this.loading(false);
              this.notification.succes(`Opublikowano grupon ${published.name}`);
            },
            () => {
              this.loading(false);
              this.notification.error("Nie udało się opublikować gruponu.");
            }
          );
        }
      });
  }

  delete() {
    this.yesNoDialog("Uwaga!", "Czy na pewno chcesz bezpowrotnie usunąć grupon?")
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.api_Groupons.delete(this.groupon.id).subscribe();
          this.router
            .navigateByUrl("/")
            .then(() => this.router.navigateByUrl(APP_ROUTES.rkmhConfiguration));
        }
      });
  }

  copyTo() {
    this.yesNoDialog(
      "Uwaga!",
      "Czy na pewno chcesz skopiować grupon do nowego szkicu?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          const copyRequest = new CreateGrouponDto(this.groupon);
          this.api_Groupons.copy(copyRequest).subscribe(
            copied => {
              this.loading(false);
              this.router
                .navigateByUrl("/")
                .then(() =>
                  this.router.navigateByUrl("grouponEditor/" + copied.id)
                );
            },
            () => this.loading(false)
          );
        }
      });
  }

  finishGroupon() {
    this.yesNoDialog("", "Czy na pewno chcesz zakończyć grupon?")
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);

          this.groupon.startDate = moment(this.groupon.startDate)

          this.groupon.endDate = moment(this.groupon.endDate)

          this.api_Groupons.finish(this.groupon.id).subscribe(
            d => {
              this.loading(false);
              this.notification.succes(`Zakończono grupon ${this.groupon.name}. Możesz teraz zadecydować o jego wykonaniu / niewykonaniu.`);
              this.loadGroupon(this.groupon.id);
            },
            e => {
              this.loading(false);
              this.notification.error("Nie udało się zakończyć gruponu.");
            });
        }
      });
  }

  IsEditable(elName: string) {
    return this.grouponService.isEditable(elName, this.groupon);
  }

  IsActive(elName: string) {
    return this.grouponService.isActive(elName, this.groupon);
  }

  minDate(propName) {
    if (this.origGroupon && this.origGroupon[propName]) {
      return this.origGroupon[propName];
    } else {
      return new Date(2019, 1, 1);
    }
  }

  addThreshold() {
    if (!this.groupon.thresholds) {
      this.groupon.thresholds = new Array<GrouponThresholdDto>();
    }
    else {
      this.groupon.thresholds = this.groupon.thresholds.sort((a, b) => a.discountThreshold > b.discountThreshold ? 1 : -1);
    }

    this.groupon.thresholds.push(
      new GrouponThresholdDto({
        discountPercent: 0,
        discountThreshold: 0,
        isCurrent: false,
        completionPercentage: 0,
        declared: 0
      })
    );
  }

  removeThreshold(threshold: GrouponThresholdDto) {
    this.yesNoDialog(
      "Uwaga!",
      "Czy na pewno chcesz usunąć wybrany próg rabatowy?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.groupon.thresholds.forEach((item, index) => {
            if (item === threshold) {
              this.groupon.thresholds.splice(index, 1);
            }
          });
        }
      });
  }

  importParticipants(file: File) {
    this.imortParticipantsFile = file;
    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      this.imortParticipantsFile = null;
      this.notification.error("Nieprawidłowy format pliku");
      return;
    }
    if (file) {
      this.yesNoDialogAnswer(
        "",
        "Czy na pewno chcesz zaktualizować listę uczestników?"
      ).subscribe(yes => {
        this.loading(true);
        if (yes) {
          this.grouponService
            .UploadParticipants(file)
            .subscribe((data: GrouponParticipantDto[]) => {
              this.groupon.participants = data;
              this.participantsDataSource = new MatTableDataSource(
                this.groupon.participants
              );
              this.participantsDataSource.paginator = this.participantsPaginator;
              this.loading(false);
              this.notification.succes("Zaktualizowano listę uczestników.");
            });
        }
        this.loading(false);
      });
    }
  }

  importPositions(file: File) {

    this.importPositionsFile = file;
    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      this.importPositionsFile = null;
      this.notification.error("Nieprawidłowy format pliku");
      return;
    }
    if (file) {
      this.yesNoDialogAnswer(
        "",
        "Czy na pewno chcesz zaktualizować listę pozycji?"
      ).subscribe(yes => {
        this.loading(true);
        if (yes) {
          this.grouponService
            .UploadPositions(file)
            .subscribe((data: GrouponProductDto[]) => {
              for (let i = 0; i < data.length; i++) {
                if (this.groupon.type == GrouponType.Package) {
                  data[i].lineNumber = 1;
                } else {
                  data[i].lineNumber = i + 1;
                }
              }

              this.groupon.products = data;
              if (this.groupon.products.length === 0) {

                this.notification.warn("Podany plik zawiera błędne pozycje");
              } else {
                let producerNew = new ProducerDto();
                producerNew.id = data[0].producerId;
                producerNew.name = data[0].producer;
                this.changeProducer(producerNew);

                this.positionsDataSource = new MatTableDataSource(this.groupon.products);
                this.positionsDataSource.paginator = this.positionsPaginator;
                this.notification.succes("Zaktualizowano listę pozycji!");
              }
            }, () =>
              this.notification.error(
                "Nie udało się zaimportować listy pozycji."
              )
            );
        }
        this.loading(false);
      });
    }
  }

  addImage(file: File) {
    if (file) {

      if (file.type != 'image/png' && file.type != 'image/jpeg') {
        this.notification.error("Niepoprawny format pliku. Obsługiwane formaty grafiki: JPG(JPEG), PNG.");
        return;
      }
      else if (file.size > this.maxFileSize) {
        this.notification.error(
          "Zbyt duży rozmiar pliku. Maksymalny dozwolony rozmiar: " +
          this.maxFileSize +
          " B"
        );
        return;
      }

      this.yesNoDialogAnswer(
        "",
        "Czy na pewno chcesz zaktualizować grafikę promocyjną?"
      ).subscribe(yes => {
        this.loading(true);
        if (yes) {
          this.previewUrl = file.name;

          if (this.groupon.id && this.groupon.id > 0) {
            this.grouponService.UpdateImage(this.groupon.id, file).subscribe(
              attachment => {
                this.groupon.image = attachment;
                this.loading(false);
                this.notification.succes("Zaktualizowano grafikę promocyjną.");
              },
              () =>
                this.notification.error(
                  "Nie udało się zaktualizować grafiki promocyjnej."
                )
            );
          } else {
            this.newImage = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.newImagePreview = reader.result;
            };
            this.loading(false);
          }

        }
        this.loading(false);
      });
    }
  }

  saveImage(file: File) {
    if (file) {
      this.previewUrl = file.name;
      this.grouponService.UpdateImage(this.groupon.id, file).subscribe(
        attachment => {
          this.groupon.image = attachment;
        },
        () =>
          this.notification.error("Nie udało się zaktualizować grafiki promocyjnej.")
      );
    }
  }

  removeImage() {
    this.yesNoDialogAnswer("", "Czy na pewno chcesz usunąć grafikę promocyjną?").subscribe(() => {
      if (this.groupon.image) {
        this.loading(true);
        this.api_Groupons.deleteImage(this.groupon.id).subscribe(
          () => {
            this.previewUrl = '';
            this.groupon.image = null;
            this.loading(false);
            this.notification.succes("Zaktualizowano grafikę promocyjną.");
          },
          () => {
            this.loading(false);
            this.notification.error("Nie udało się usunąć grafiki promocyjnej.");
          }
        );
      }
      else {
        this.newImagePreview = '';
        this.newImage = null;
        this.previewUrl = '';
      }
    });
  }

  addNotificationAttachment(
    file: File,
    grouponNotification: GrouponNotificationDto
  ) {
    if (grouponNotification.id === 0) {
      this.notification.warn("Zapisz grupon przed dodaniem załącznika");
    }

    if (file) {
      this.yesNoDialogAnswer(
        "",
        "Czy na pewno chcesz załączyć plik do wiadomości? " +
        "Uwaga: " +
        "Załączniki zawierające dane osobowe, wysyłane mailowo powinny być zaszyfrowane, a hasło do nich dostarczone niezależnie!"
      ).subscribe(yes => {
        this.loading(true);
        if (yes) {
          if (file.size > this.maxFileSize) {
            this.notification.error(
              "Zbyt duży rozmiar pliku. Maksymalny dozwolony rozmiar: " +
              this.maxFileSize +
              " B"
            )
          }
          else if (file.type != 'image/jpeg' && file.type != 'application/pdf') {
            this.notification.error("Obsługiwane formaty załączników to JPG/JPEG oraz PDF.");
          } else {
            this.grouponService
              .UploadNotificationAttachment(
                this.groupon.id,
                grouponNotification.id,
                file
              )
              .subscribe(
                attachment => {
                  grouponNotification.attachmentPath = attachment.filePath;
                  grouponNotification.attachmentType = attachment.fileType;
                  this.loading(false);
                  this.notification.succes("Załączono plik do wiadomości.");
                },
                () => this.notification.error("Nie udało się dodać załącznika.")
              );
          }
        }
        this.loading(false);
      });
    }
  }

  deleteAttachment(grouponNotification: GrouponNotificationDto) {
    this.yesNoDialog(
      "Uwaga!",
      "Czy na pewno chcesz bezpowrotnie usunąć załącznik?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          this.api_Groupons
            .deleteNotificationAttachment(
              this.groupon.id,
              grouponNotification.id
            )
            .subscribe(() => {
              grouponNotification.attachmentPath = null;
              grouponNotification.attachmentType = null;
              this.loading(false);
              this.notification.succes("Usunięto załącznik.");
            });
        }
      });
    this.loading(false);
  }

  _handleNotificationReaderLoaded() { }

  removeProduct(position: GrouponProductDto) {
    this.yesNoDialog(
      "Uwaga!",
      "Czy na pewno chcesz usunąć wybraną pozycję gruponu?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.groupon.products.forEach((item, index) => {
            if (item === position) {
              this.groupon.products.splice(index, 1);
            }
          });
          this.positionsDataSource = new MatTableDataSource(
            this.groupon.products
          );
          this.positionsDataSource.sort = this.sort;
          this.positionsDataSource.paginator = this.positionsPaginator;
          if (this.groupon.products.length > 0) {
            this.isReadonly = true;
          } else {
            this.isReadonly = false;
          }
        }
      });
  }

  isAdHocNotification(notification: GrouponNotificationDto) {
    if (notification.type === 2) {
      return true;
    }
    return false;
  }

  addNewNotification(lastNotificationOfType: GrouponNotificationDto) {
    if (!this.groupon.notifications) {
      this.groupon.notifications = new Array<GrouponNotificationDto>();
    }
    const newNotification = lastNotificationOfType.clone();
    newNotification.message = "";

    const index = this.groupon.notifications.findIndex(
      x => x === lastNotificationOfType
    );
    this.groupon.notifications.splice(index + 1, 0, newNotification);
  }

  deleteNotification(notification: GrouponNotificationDto) {
    this.yesNoDialogAnswer(
      "",
      "Czy na pewno chcesz usunąć wiadomość?"
    ).subscribe(yes => {
      if (yes) {
        const index = this.groupon.notifications.findIndex(
          x => x === notification
        );
        if (index > -1) {
          this.groupon.notifications.splice(index, 1);
        }
      }
    });
  }

  isLastOfType(notification: GrouponNotificationDto): boolean {
    const currentItemIdx = this.groupon.notifications.findIndex(
      x => x === notification
    );
    return !this.groupon.notifications.some(
      (x, i) =>
        x.type === notification.type && x !== notification && i > currentItemIdx
    );
  }

  isOneOfType(notification: GrouponNotificationDto): boolean {
    return this.groupon.notifications.some(
      (x) => x.type === notification.type && x !== notification
    );
  }

  sendAdHocNotification(notification: GrouponNotificationDto) {
    this.yesNoDialog("Uwaga", "Czy na pewno chcesz wysłać wiadomość?")
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          notification.isActive = true;
          this.grouponService
            .sendNotification(this.groupon.id, notification)
            .subscribe(
              () => {
                this.loading(false);
                this.notification.succes("Przekazano wiadomość do wysłania.");
              },
              () => {
                this.loading(false);
                this.notification.error("Nie udało się wysłać wiadomości.");
              }
            );
        }
      });
  }

  updateMultiplicity(row: GrouponProductDto, eventValue) {
    if (!isNaN(eventValue) && eventValue >= 0) {
      row.multiplicity = eventValue;
    }
  }

  updateLineNumber(row, eventValue) {
    if (eventValue && eventValue > 0) {
      if (row.lineNumber !== eventValue) {
        row.lineNumber = eventValue;
        // sort positions
        this.sort.active = "lineNumber";
        this.sort.sortChange.emit();
        this.sort._stateChanges.next();
      }
    }
  }

  updateNotificationDaysBeforeEndNumber(
    row: GrouponNotificationDto,
    eventValue
  ) {
    if (eventValue && eventValue > 0) {
      if (row.daysBeforeEnd !== eventValue) {
        row.daysBeforeEnd = eventValue;
      }
    }
  }

  search = false;
  showPreSearchBar() {
    return this.search === false;
  }

  initiateSearch() {
    this.search = true;
  }
  showSearchBar() {
    return this.search === true;
  }
  endSearch() {
    return (this.search = false);
  }
  submit() {
    // this.applyFilter(searchtxt);
  }
  searchPlaceholderMaterials: string = Strings.SearchMaterialsPlaceholder;
  searchPlaceholderProducers: string = Strings.SearchProducersPlaceholder;

  compareFnUser(a: User, b: User) {
    return a && b && a.email === b.email;
  }

  applyParticipantsFilter(filterValue: string) {
    this.participantsDataSource.filter = filterValue.trim().toLowerCase();
  }

  valuechange(obj: GrouponThresholdDto, property,  eventValue, type) {
    if (event && event.currentTarget['valueAsNumber'] < 0) {
      obj[property] = 0;
    } else if (parseInt(event.currentTarget['valueAsNumber']) > 100 && property == "discountPercent" && type== GrouponThresholdType.Percent ) {
      obj.discountPercent = 100;
      return;
    } else if (property == "discountThreshold") {
      obj[property] = parseInt(event.currentTarget['valueAsNumber']);
      return;
    }
  }

  updateThresholds(position: GrouponProductDto, eventValue: GrouponThresholdDto[]) {
    if (eventValue)
      position.thresholds = eventValue;
  }

  saveRealizationInfo(state: GrouponRealizationStatus) {
    this.yesNoDialog(
      "Uwaga",
      "Czy na pewno chcesz potwierdzić informacje o realizacji gruponu?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          const request = new SetRealizationStatusDto({
            realizationStatus: state,
            promotionCode: this.groupon.promotionCode
          });

          this.api_Groupons
            .setRealizationStatus(this.groupon.id, request)
            .subscribe(
              canSendOrders => {
                this.loading(false);
                this.groupon.canSendOrders = canSendOrders;
                this.groupon.realizationStatus = state;

                this.notification.succes(
                  "Zaktualizowano informacje o realizacji gruponu."
                );

                if (state === GrouponRealizationStatus.Failed) {
                  this.loadGroupon(this.groupon.id)
                  this.selectedTab.setValue(0);
                }
              },
              () => {
                this.loading(false);
                this.notification.error(
                  "Nie udało się zaktualizować informacji o realizacji gruponu."
                );
              }
            );
        }
      });
  }

  sendOrders() {
    this.yesNoDialog(
      "Uwaga! Tej akcji nie można cofnąć.",
      "Czy na pewno chcesz wygenerować i zlecić wysyłkę zamówień dla uczestników gruponu?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.api_Groupons
            .placeOrders(this.groupon.id)
            .subscribe(
              () => {
                this.groupon.canSendOrders = false;
                this.notification.succes(
                  "Pomyślnie zlecono realizację zamówień"
                )
              },
              () =>
                this.notification.error("Nie udało się zlecić wysyłki zamówień")
            );
        }
      });
  }

  backToConfigurationPanel() {
    this.yesNoDialogAnswer(
      "",
      "Czy na pewno chcesz zamknąć okno edytora?"
    ).subscribe(confirmation => {
      if (confirmation) {
        this.location.back();
      }
    });
  }

  toggleNotification(notification: GrouponNotificationDto) {
    notification.isActive = !notification.isActive;
  }

  get isValid(): boolean {
    if (this.form) {
      this.cdr.detectChanges();
      return this.form.valid;
    } else {
      return false;
    }
  }

  getGrouponLog() {
    this.loading(true);
    this.grouponService.getGrouponLog(this.groupon.id).subscribe(
      blob => {
        this.loading(true);
        this.OpenFileFromStream(
          blob,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          this.groupon.name + "_LogZmian.xlsx"
        );
        this.loading(false);
      },
      () => {
        this.notification.error("Nie udało się pobrać logu gruponu");
        this.loading(false);
      }
    );
    this.loading(false);
  }

  getReport() {
    this.loading(true);
    this.api_Groupons.getReport([this.groupon.id]).subscribe(
      (fileResponse: FileResponse) => {
        this.OpenFileFromStream(
          fileResponse.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          fileResponse.fileName || `${this.groupon.name}_${moment(moment.now()).format('YYYY-MM-DD')}.xlsx`);
        this.loading(false);
      }
    ),
      () => {
        this.loading(false);
        this.notification.error("Nie udało się wygenerować raportu");
      }
  }


  confirmOptionChange(e: MouseEvent, grouponType: GrouponType, thresholdType: GrouponThresholdType, option: string) {
    e.preventDefault();
    if (this.groupon.type == grouponType && this.groupon.thresholdType == thresholdType) {
      return;
    }

    if (
      (!this.groupon.products ||
        !this.groupon.products.some(x => x != undefined && x != null)) &&
      (!this.groupon.thresholds ||
        !this.groupon.thresholds.some(x => x != undefined && x != null))
    ) {
      this.groupon.type = grouponType;
      this.groupon.thresholdType = thresholdType;
      return;
    }

    this.yesNoDialogAnswer(
      "Uwaga!",
      this.changeOptionAlert(option)
    ).subscribe(confirmation => {
      if (confirmation) {
        this.groupon.products = new Array<GrouponProductDto>();
        this.positionsDataSource = new MatTableDataSource(
          this.groupon.products
        );
        this.positionsDataSource.sort = this.sort;
        this.groupon.thresholds = new Array<GrouponThresholdDto>();
        this.groupon.type = grouponType;
        this.groupon.thresholdType = thresholdType;
        return;
      }
    });
  }

  changeOptionAlert(option: string){
   return "Zmiana rodzaju " 
   + ((option==='Type') ? 'gruponu' : 'rabatu') +
   " spowoduje usunięcie dodanych pozycji oraz progów. Czy na pewno chcesz kontynuować?"
  }

  get multiplicityLabel(): string {
    switch (this.groupon.type) {
      case GrouponType.Default:
        return "Minimum";
      case GrouponType.Package:
        return "Ilość w pakiecie";
      case GrouponType.Mixed:
        return "Minimum / ilość w pakiecie";
      default:
        return "";
    }
  }

  clearParticipants() {
    this.yesNoDialogAnswer(
      "",
      "Czy na pewno chcesz usunąć uczestników?"
    ).subscribe(confirmation => {
      if (confirmation) {
        this.groupon.participants = [];
        this.participantsDataSource = new MatTableDataSource<GrouponParticipantDto>();
      }
    });
  }

  activeUsers() {
    if (this.users && this.groupon) {
      let usersToSelect = this.users.filter(u => u.isActive);
      if (this.currentOwner && !usersToSelect.some(u => u.email == this.currentOwner.email) && this.groupon.status !== GrouponStatus.Draft) {
        usersToSelect.unshift(this.currentOwner);
      }
      return usersToSelect;
    }
  }

  validatePromotionCode(value: string) {

    const re = /^(1[0-9]{8})$/;
    if (!value || re.test(value) !== true) {
      return false;
    }
    return true;
  }

  get thresholdsUnit(): string{
    switch(this.groupon.thresholdType){
      case GrouponThresholdType.Percent:
        return "%";
      case GrouponThresholdType.Amount:
        return "zł";

    }
  }
}
