import {
  Component,
  OnInit,
  Injector,
  Output,
  EventEmitter,
  Inject
} from "@angular/core";
import {
  GrouponDeclarationPositionDto,
  GrouponDeclarationDto, GrouponProductDto,
  GrouponProductThresholdDto,
  API_GrouponDeclarations, GrouponThresholdType,
  GrouponType, GrouponThresholdDto, GrouponDeclarationPharmacyDto
} from "src/app/services/service-proxies/api";
import { ApplicationComponent } from "../../application.Component";
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface QuantityDialogData {
  pharmacyId: string,
  pharmacyName: string,
  grouponId: number,
  declaredPositions: GrouponDeclarationPositionDto[],
  salesOrganizationId: string
}

@Component({
  selector: 'app-declared-quantities-editor',
  templateUrl: "declared-quantities-editor.component.html",
  styleUrls: ['declared-quantities-editor.component.scss']
})
export class DeclaredQuantitiesEditorComponent extends ApplicationComponent implements OnInit {

  declaration: GrouponDeclarationDto;

  displayedColumns: string[] = ['bloz', 'name', 'netPrice', 'priceAfterMaxDiscount', 'priceAfterDiscount', 'minimum', 'declaredQuantity'];
  positionsDataSource: MatTableDataSource<GrouponDeclarationPositionDto> = new MatTableDataSource();
  totalSum: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalDiscount: number;

  get isAnyActive(): boolean {
    return this.declaration && this.declaration.positions &&
      this.declaration.positions.some(p => p.isActive);
  }

  get pharmacyName(): string {
    return this.data?.pharmacyName;
  }

  @Output() declaredQuantityChanged: EventEmitter<GrouponDeclarationPharmacyDto> = new EventEmitter();

  constructor(injector: Injector, private route: ActivatedRoute, private api: API_GrouponDeclarations, public dialogRef: MatDialogRef<DeclaredQuantitiesEditorComponent>, @Inject(MAT_DIALOG_DATA) public data: QuantityDialogData) {
    super(injector);
  }

  ngOnInit() {

    if (this.data.grouponId && !this.declaration) {
      this.loading(true);
      this.api.get(this.data.grouponId, this.data.pharmacyId.toString(), this.data.salesOrganizationId).subscribe((dec: GrouponDeclarationDto) => {

        console.log('pos: ', dec.positions);
        this.setGrouponDeclaration(dec);
        this.recalculate();
        this.loading(false);
      });
    }
  }

  setGrouponDeclaration(dec: GrouponDeclarationDto) {
    this.declaration = dec;

    if (this.data.declaredPositions) {
      this.declaration.positions.forEach((x, i) => {
        x.declaredQuantity = this.data.declaredPositions[i].declaredQuantity;
      });
    }

    this.positionsDataSource = new MatTableDataSource(this.declaration.positions);
  }

  changeAllDeclarationsBy(val: number) {
    if (val) {
      this.declaration.positions.forEach(p => {
        if (!p.isActive) return;

        p.declaredQuantity += val;
        if (p.declaredQuantity < 0)
          p.declaredQuantity = 0;
      });

      this.recalculate();
    }
  }

  recalculate() {
    this.declaration["nextThreshold"] = this.getNextThreshold();
    // todo refactor
    let sumInTotal = 0.00;
    this.declaration.positions.forEach(p => {
      let lineTotal = 0.00;
      p.products.forEach(pr => {
        let discount = this.getDiscount(pr);
        let pricePrognosed = pr.price * (1.00 - discount / 100.00);
        if (this.declaration.grouponThresholdType == GrouponThresholdType.Amount) {
          pricePrognosed = pr.price - discount;
        }
        pr['pricePrognosed'] = pricePrognosed = parseFloat(pricePrognosed.toFixed(2));
        pr['discount'] = discount = discount;
        lineTotal += (pricePrognosed ? pricePrognosed : 0) * (p.isPackage || this.declaration.grouponType == GrouponType.Package ? pr.multiplicity : 1);
      });

      p['positionTotal'] = (parseFloat(lineTotal.toFixed(2)) * p.declaredQuantity);
      p['positionTotalDisplayString'] = p['positionTotal'].toFixed(2);
      sumInTotal += p['positionTotal'] ? p['positionTotal'] : 0;
    });
    this.totalSum.next(parseFloat(sumInTotal.toFixed(2)));
    this.totalDiscount = parseFloat((this.maxThreshold(this.declaration) + this.declaration.whDiscount).toFixed(2));
  }

  getCurrentThreshold(pr: GrouponProductDto) {
    if (pr && pr.thresholds) {
      let t = pr.thresholds.find(t => t.isCurrent);
      if (t) return t.discountPercent
      else return 0;
    }
    else return 0;
  }

  private getNextThreshold(): GrouponThresholdDto {
    if (this.declaration && this.declaration.grouponThresholds) {
      let idx = this.declaration.grouponThresholds.findIndex(t => t.isCurrent);
      if (idx > -1) {
        //is there a next threshold?
        if (this.declaration.grouponThresholds[idx + 1]) {
          return this.declaration.grouponThresholds[idx + 1];
        }
        else { //its actually last threshold
          return this.declaration.grouponThresholds[idx];
        }
      }
      //first threshold not reached yet
      return this.declaration.grouponThresholds[0];
    }
    else return null
  }

  getMaxProductDiscount(pr: GrouponProductDto) {
    if (pr && pr.thresholds) {
      return Math.max(...pr.thresholds.map(t => t.discountPercent), 0);
    }
    else return 0;
  }

  maxThreshold(groupon: GrouponDeclarationDto): number {
    switch (groupon.grouponType) {
      case GrouponType.Default:
        let productThresholds = new Array<GrouponProductThresholdDto>();
        groupon.positions.forEach(p => p.products.forEach(pr => productThresholds.push(...pr.thresholds)));
        return Math.max(...productThresholds.map(t => t.discountPercent), 0);
      case GrouponType.Mixed:
      case GrouponType.Package:
        return Math.max(...groupon.grouponThresholds.map(t => t.discountPercent), 0);
      default: return null;
    }
  }

  getMaxTotalProductDiscount(pr: GrouponProductDto) {
    switch (this.declaration.grouponType) {
      case GrouponType.Default:
        return parseFloat((this.getMaxProductDiscount(pr) + this.declaration.whDiscount).toFixed(2));
      case GrouponType.Mixed:
      case GrouponType.Package:
        return parseFloat((this.getMaxProductDiscount(pr) + this.maxThreshold(this.declaration) + this.declaration.whDiscount).toFixed(2));
    }
  }

  getMinimumPricePrognosed(pr: GrouponProductDto) {
    switch (this.declaration.grouponThresholdType) {
      case GrouponThresholdType.Percent:
        return pr.price * (1.00 - this.getMaxTotalProductDiscount(pr) / 100.00);
      case GrouponThresholdType.Amount:
        return pr.price - this.getMaxTotalProductDiscount(pr);
      default:
        return 0;
    }
  }

  private getCurrentGrouponThreshold() {
    if (this.declaration && this.declaration.grouponThresholds) {
      let t = [...this.declaration.grouponThresholds].find(t => t.isCurrent);
      if (t) return t.discountPercent
      else return 0;
    }
    else return 0;
  }

  getDiscount(pr: GrouponProductDto) {
    switch (this.declaration.grouponType) {
      case GrouponType.Default:
        return parseFloat((this.getCurrentThreshold(pr) + this.declaration.whDiscount).toFixed(2));
      case GrouponType.Mixed:
      case GrouponType.Package:
        return parseFloat((this.getCurrentGrouponThreshold() + this.getCurrentThreshold(pr) + this.declaration.whDiscount).toFixed(2));
      default:
        return 0;
    }
  }

  getDiscountAmount(pr: GrouponProductDto) {
    switch (this.declaration.grouponThresholdType) {
      case GrouponThresholdType.Percent:
        return (1.00 - this.getDiscount(pr) / 100.0) * pr.price;
      case GrouponThresholdType.Amount:
        return pr.price - this.getDiscount(pr);
      default:
        return 0;
    }
  }

  updatedeclaredQuantity(position: GrouponDeclarationPositionDto, eventValue: number) {
    if (eventValue != undefined && eventValue > -1 && position.declaredQuantity != eventValue) {
      position.declaredQuantity = Math.round(eventValue);
      this.declaration.positions.forEach(p => {
        if (p != position) return;

        p.declaredQuantity = position.declaredQuantity;
        if (p.declaredQuantity < 0)
          p.declaredQuantity = 0;
      });

    }
    this.recalculate();
  }

  private validateDeclaration(): boolean {

    switch (this.declaration.grouponType) {
      case GrouponType.Default:
      case GrouponType.Mixed:
        return !this.declaration.positions.some(
          p => p.isActive && !p.isPackage && p.declaredQuantity > 0 && p.products.some(
            pr => p.declaredQuantity < pr.multiplicity));
    }

    return true;
  }

  onSubmit() {

    if (!this.validateDeclaration()) {
      this.notification.error('Niepoprawna wartość deklaracji.');
      return;
    }

    let isResignation = this.declaration.positions.every(d => d.declaredQuantity <= 0)

    if (isResignation) {
      this.yesNoDialog("", "Wszystkie pozycje zostały wyzerowane. Czy na pewno chcesz zrezygnować z udziału tej apteki w gruponie?")
        .afterClosed().subscribe(yes => {
          if (yes) {
            this.onAccept();
          }
        });
    }
    else {
      this.onAccept();
    }
  }

  private onAccept() {

    let evt = new GrouponDeclarationPharmacyDto();
    evt.pharmacyId = this.data.pharmacyId.toString();
    evt.declaredPositions = this.declaration.positions;

    this.dialogRef.close(evt);
  }

  onCancel() {
    this.dialogRef.close();
  }
}