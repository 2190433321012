import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'translateOrderStatus'})
export class OrderStatusTranslator implements PipeTransform {
  transform(value: any): any {
    
    switch(value) {
        case -1:
            return 'Niepowodzenie';
        case 0:
            return 'Deklaracja';  
        case 1:
            return 'Wysłano do realizacji';
        case 2:
            return 'W trakcie realizacji';
        case 3:
            return 'W trakcie realizacji'
        case 4:
            return 'Zrealizowano'
        case 5:
            return 'Zrealizowano częściowo'
        case 6:
            return 'Odrzucono'
        case 7:
            return 'Realizacja w toku'
        case "-1":
            return 'Niepowodzenie';
        case "0":
            return 'Deklaracja';  
        case "1":
            return 'Wysłano do realizacji';
        case "2":
            return 'W trakcie realizacji';
        case "3":
            return 'W trakcie realizacji'
        case "4":
            return 'Zrealizowano'
        case "5":
            return 'Zrealizowano częściowo'
        case "6":
            return 'Odrzucono'
        case "7":
            return 'Realizacja w toku'
        default:
          return 'Brak statusu';
      }
}
}
