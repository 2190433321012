import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialDesignModule } from '../modules/materialDesignModule';
import { NgModule, APP_INITIALIZER, ErrorHandler, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PharmacySelectorComponent } from './components/pharmacy-selector/pharmacy-selector.component';
import { JoinRpmComponent } from './components/join-rpm/join-rpm.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GrouponListComponent } from "./components/groupons/groupon-list/groupon-list.component";
import { GrouponDeclarationComponent } from "./components/groupons/groupon-declaration/grouponDeclaration.component";
import { AuthGuardService } from './services/auth-guard.service';
import { RkmhConfigurationComponent } from './components/admin/rkmh-configuration/rkmh-configuration.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SamDragAndDropGridModule } from '@sam-1994/ngx-drag-and-drop-grid';
import { DynamicComponentWrapper } from './components/dynamic-component-wrapper';
import { UserActiveActionsComponent } from './components/dashboard/dashboard-tiles/user-active-actions/user-active-actions.component';
import { PharmacyActiveActionsComponent } from './components/dashboard/dashboard-tiles/pharmacy-active-actions/pharmacy-active-actions.component';
import { ContractAccomplishmentComponent } from './components/dashboard/dashboard-tiles/contract-accomplishment/contract-accomplishment.component';
import { API_BASE_URL, API_Dashboard, API_Groupons, API_GrouponCards, API_User, API_Pharmacy, API_Materials, API_GrouponDeclarations, API_Producers, API_BusinessConditions, API_YourPurchases } from './services/service-proxies/api';
import { PharmacyRankingsComponent } from './components/dashboard/dashboard-tiles/pharmacy-rankings/pharmacy-rankings.component';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { AllActiveActionsComponent } from './components/dashboard/dashboard-tiles/all-active-actions/all-active-actions.component';
import { AccomplishedActionsComponent } from './components/dashboard/dashboard-tiles/accomplished-actions/accomplished-actions.component';
import { FailedActionsComponent } from './components/dashboard/dashboard-tiles/failed-actions/failed-actions.component';
import { GrouponEditorComponent } from "./components/groupons/groupon-editor/groupon-editor.component";
import { getMatPaginatorPl } from './language/pl/matPaginatorIntl';
import { UserSessionService } from './services/user-session-service.service';
import { AppInitializeService } from './services/app-initialize.service';
import { ConfigurationService } from './services/configuration.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineNumberEditComponent } from './components/shared/inline-number-edit/inline-number-edit.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { InfoComponent } from './components/shared/notifications/info/info.component';
import { WarnComponent } from './components/shared/notifications/warn/warn.component';
import { SuccesComponent } from './components/shared/notifications/succes/succes.component';
import { ErrorComponent } from './components/shared/notifications/error/error.component';
import { ErrorDialogComponent } from './components/shared/dialogs/error-dialog/error-dialog.component';
import { InfoDialogComponent } from './components/shared/dialogs/info-dialog/info-dialog.component';
import { SingleInputDialogComponent } from './components/shared/dialogs/single-input-dialog/single-input-dialog.component';
import { YesNoDialogComponent } from './components/shared/dialogs/yes-no-dialog/yes-no-dialog.component';
import { NotFound404Component } from './components/shared/errors/not-found404/not-found404.component';
import { GlobalErrorHandler } from './services/error-handling/global-error-handler';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { GridsterModule } from 'angular-gridster2';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Location } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { SizeDetectorComponent } from './components/shared/size-detector/size-detector.component';
import { ThresholdsInlineEditorComponent } from './components/groupons/thresholds-inline-editor/thresholds-inline-editor.component';
import { YourShoppingComponent } from './components/your-shopping/your-shopping.component';
import { ProductComponent } from './components/product/product.component';
import { MatomoModule } from 'ngx-matomo';
import { CountdownModule, CountdownGlobalConfig } from 'ngx-countdown';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { SliderComponent } from './components/dashboard/slider/slider.component';
import { SliderConfigurationComponent } from './components/admin/slider-configuration/slider-configuration.component';
import { PipesModule } from './pipes.module';
import { SlideConfigurationComponent } from './components/admin/slider-configuration/slide-configuration/slide-configuration.component';
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { DeclarationPharmaciesSelectorComponent } from './components/groupons/declaration-pharmacies-selector/declaration-pharmacies-selector.component';
import { JwtInterceptor } from './services/auth/oidc/jwt.interceptor';
import { DeclaredQuantitiesEditorComponent } from './components/groupons/declared-quantities-editor/declared-quantities-editor.component';
import { AuthenticationGuard } from './services/auth/oidc/authentication.guard';


registerLocaleData(localePl, 'pl');

export function AppInit(appInitializeService: AppInitializeService): () => Promise<any> {
    return () => appInitializeService.OnPreload();
};

export function getBaseApiUrl(configurationService: ConfigurationService): string {
    return configurationService.getConfig().BackendServiceApiUrl
};

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const customTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 500,
    hideDelay: 100,
    touchendHideDelay: 100
}

export function countdownConfigFactory(): CountdownGlobalConfig {
    var c = new CountdownGlobalConfig('pl');
    c.format = "h:mm:ss";
    return c;
}  

 export function storageFactory(): OAuthStorage {
    return localStorage;
  }

@NgModule({
    declarations: [
        AppComponent,
        PharmacySelectorComponent,
        JoinRpmComponent,
        RkmhConfigurationComponent,
        GrouponListComponent,
        GrouponDeclarationComponent,
        GrouponEditorComponent,
        GrouponDeclarationComponent,
        DashboardComponent,
        DynamicComponentWrapper,
        UserActiveActionsComponent,
        PharmacyActiveActionsComponent,
        ContractAccomplishmentComponent,
        PharmacyRankingsComponent,
        AllActiveActionsComponent,
        AccomplishedActionsComponent,
        FailedActionsComponent,
        InlineNumberEditComponent,
        InfoComponent,
        WarnComponent,
        SuccesComponent,
        ErrorComponent,
        ErrorDialogComponent,
        InfoDialogComponent,
        SingleInputDialogComponent,
        YesNoDialogComponent,
        NotFound404Component,
        SizeDetectorComponent,
        ThresholdsInlineEditorComponent,
        YourShoppingComponent,
        ProductComponent,
        SliderComponent,
        SliderConfigurationComponent,
        SlideConfigurationComponent,
        DeclarationPharmaciesSelectorComponent,
        DeclaredQuantitiesEditorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialDesignModule,
        HttpClientModule,
        MatToolbarModule,
        MatTableModule,
        SamDragAndDropGridModule,
        FormsModule,
        MatNativeDateModule,
        SatPopoverModule,
        ReactiveFormsModule,
        GridsterModule,
        NgxSpinnerModule,
        NgScrollbarModule,
        MatomoModule,
        CountdownModule,
        MatCarouselModule.forRoot(),
        PipesModule,
        OAuthModule.forRoot({
            resourceServer: {
              sendAccessToken: true,
            },
          }),
      
    ],
    providers: [
        { provide: API_BASE_URL, useFactory: getBaseApiUrl, deps: [ConfigurationService], multi: false },
        { provide: APP_INITIALIZER, useFactory: AppInit, deps: [AppInitializeService], multi: true },
        AuthenticationGuard, AuthGuardService, UserSessionService, 
        API_Dashboard, API_Groupons, API_GrouponCards, API_User, API_Pharmacy, API_Materials, API_GrouponDeclarations, API_Producers, API_BusinessConditions, API_YourPurchases,
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: MatPaginatorIntl, useValue: getMatPaginatorPl() },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useValue: "pl" },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults },
        Location,
        { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },      
        { provide: OAuthStorage, useFactory: storageFactory },
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    
    ],
    bootstrap: [AppComponent],

    entryComponents: [
        UserActiveActionsComponent,
        PharmacyActiveActionsComponent,
        ContractAccomplishmentComponent,
        PharmacyRankingsComponent,
        AllActiveActionsComponent,
        AccomplishedActionsComponent,
        FailedActionsComponent,
        InfoComponent,
        WarnComponent,
        SuccesComponent,
        ErrorComponent,
        ErrorDialogComponent,
        InfoDialogComponent,
        SingleInputDialogComponent,
        YesNoDialogComponent,
        DeclarationPharmaciesSelectorComponent
    ]
})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry) {
        iconRegistry.setDefaultFontSetClass('material-icons-sharp');
    }
}
