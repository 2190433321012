import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/Configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private config: Configuration;

  constructor(private http: HttpClient) {
  }

  getConfig(): Configuration {
      return this.config;
  }
  loadConfig(): Promise<any> {
         return this.http.get<Configuration>('app.config.json').toPromise()
          .then((data: Configuration) => {
                  this.config = data;
                  return data;
          });
  }
}
