import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Injector, QueryList } from '@angular/core';
import { Chart } from 'chart.js'
import { ApplicationComponent } from 'src/app/components/application.Component';
import { API_Dashboard, PharmacyContractAccomplishmentDto, ContractAccomplishmentPeriod } from 'src/app/services/service-proxies/api';
import { MatRadioChange } from '@angular/material/radio';
import { UserSessionService } from 'src/app/services/user-session-service.service';

@Component({
  selector: 'app-contract-accomplishment',
  templateUrl: './contract-accomplishment.component.html',
  styleUrls: ['./contract-accomplishment.component.scss']
})
export class ContractAccomplishmentComponent extends ApplicationComponent implements OnInit, AfterViewInit {

  private chartNativeElement: ElementRef
  @ViewChild('canvas', { static: false }) set content(content: ElementRef) {
    this.chartNativeElement = content;
  }

  ContractAccomplishmentPeriodEnum = ContractAccomplishmentPeriod;
  chart: any;
  period: ContractAccomplishmentPeriod = ContractAccomplishmentPeriod.Current;
  data: PharmacyContractAccomplishmentDto;

  constructor(injector: Injector, private api: API_Dashboard, private session: UserSessionService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.session.current.subscribe(s => {
      if (s && s.selectedPharmacy)
        this.loadingLocal(true, 'contractAccomplishmentSpinner')
      this.loadData(s.selectedPharmacy.customerId);
    });
  }

  loadData(customerId: number) {
    this.api.getPharmacyContractAccomplishmentData(customerId, this.period).subscribe(p => {
      this.data = p;
      this.loading(false);
      this.loadingLocal(false, 'contractAccomplishmentSpinner');
      if (this.chartNativeElement != null) {
        let remaining = parseFloat((this.data.threshold - this.data.salesValue > 0 ? this.data.threshold - this.data.salesValue : 0).toFixed(2));
        let salesPercent = remaining > 0 ? parseFloat((this.data.salesValue / this.data.threshold * 100).toFixed(2)) : 100;
        let remainingPercent = parseFloat((100 - salesPercent).toFixed(2));

        if (this.chart != null && this.chart instanceof Chart) {
          this.chart.destroy();
        }
        this.chart = new Chart(this.chartNativeElement.nativeElement, {
          type: 'pie',
          data: {
            labels: [`Wykonano: ${this.data.salesValue} zł (${salesPercent}%)`, `Pozostało: ${remaining} zł (${remainingPercent}%)`],
            datasets: [{
              data: [this.data.salesValue, remaining],
              backgroundColor: [
                'RGBA(21,221,8,0.7)',
                'RGBA(221,1,16,0.7)',
              ],
              borderWidth: 2,
              fill: true
            },
            ]
          },
          options: {
            title: {
              display: true,
              text: [`Realizacja celu`],
              position: 'top'
            },
            legend: {
              display: true,
              position: 'bottom',
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return data.labels[tooltipItem.index] || '';
                }
              }
            }
          }
        });
      }
    });
  }

  periodChange(change: MatRadioChange) {
    if (!this.session.currentValue && !this.session.currentValue.selectedPharmacy) {
    }
    this.period = change.value;
    this.loadingLocal(true, 'contractAccomplishmentSpinner');
    this.loadData(this.session.currentValue.selectedPharmacy.customerId);
  }
}