import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { APP_ROUTES } from "../APP_ROUTES";
import { ApplicationComponent } from "../application.Component";
import { CookieService } from "ngx-cookie-service";
import { HttpErrorResponse } from "@angular/common/http";
import { UserSessionService } from "src/app/services/user-session-service.service";
import { SelectedPharmacy } from "src/app/models/SelectedPharmacy";
import { Strings } from "src/app/models/Strings";
import { GridsterConfig, GridType, CompactType } from "angular-gridster2";
import {
  API_Pharmacy,
  PharmacyDto,
  PagedResultOfPharmacyDto,
  SalesOrganizationDto
} from "src/app/services/service-proxies/api";
import {
  trigger,
  transition,
  style,
  animate,
  state
} from "@angular/animations";
import { FormControl } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { SCREEN_SIZE } from "src/app/models/screen-size";
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: "app-pharmacy-selector",
  templateUrl: "./pharmacy-selector.component.html",
  styleUrls: ["./pharmacy-selector.component.scss"],
  providers: [CookieService],
  animations: [
    trigger("fade", [
      // the "in" style determines the "resting" state of the element when it is visible.
      state("in", style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(":enter", [style({ opacity: 0 }), animate(600)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(":leave", animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class PharmacySelectorComponent extends ApplicationComponent
  implements AfterViewInit, OnDestroy {
  @ViewChild("mainPaginator", { static: false }) mainPaginator: MatPaginator;
  @ViewChild("salesOrganizationPaginator", { static: false }) salesOrganizationsPaginator: MatPaginator;
  constructor(
    injector: Injector,
    private pharmacyApi: API_Pharmacy,
    private cookieService: CookieService,
    private session: UserSessionService,
    private cdr: ChangeDetectorRef,
    private appComponent: AppComponent
  ) {
    super(injector);
  }

  remeberSelection: boolean = false;
  selectedPharmacy: PharmacyDto = null;
  pharmaciesDataSource: MatTableDataSource<PharmacyDto> = new MatTableDataSource<PharmacyDto>();
  pharmaciesSearchControl = new FormControl();
  pharmaciesSearchSubscription$: Subscription;
  noPharmaciesOwned: boolean;
  iteration: number;
  salesOrganizationIds: SalesOrganizationDto[];

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.loadPharmacies("", 9, 0);
    this.initSearch();
    this.screenSizeService.onResize$.subscribe(x => {
      setTimeout(() => {
        this.size = x;
        this.resizeGrid();
      });
    });
  }

  ngOnDestroy(): void {
    if (this.pharmaciesSearchSubscription$)
      this.pharmaciesSearchSubscription$.unsubscribe();
  }

  initSearch() {
    this.pharmaciesSearchSubscription$ = this.pharmaciesSearchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.pharmaciesDataSource = new MatTableDataSource<PharmacyDto>();
          this.loading(true);
        }),
        switchMap((value: string) =>
          value != null
            ? this.pharmacyApi
              .get(
                this.mainPaginator.pageSize,
                this.mainPaginator.pageIndex,
                value
              )
              .pipe(
                finalize(() => {
                  if (this.selectedPharmacy != null && this.iteration === undefined) {
                    this.salesOrganizationIds = this.selectedPharmacy.salesOrganizationIds;
                    this.selectedPharmacy.salesOrganizationIds = this.salesOrganizationIds.filter(
                      x => x.name.toLocaleLowerCase().includes(value)
                        || x.id.toLocaleLowerCase().includes(value)).sort((a, b) => a.name.trim().localeCompare(b.name));
                    this.iteration++;
                  } else if (this.selectedPharmacy != null && this.iteration !== undefined) {
                    this.selectedPharmacy.salesOrganizationIds = this.salesOrganizationIds.filter(
                      x => x.name.toLocaleLowerCase().includes(value)
                        || x.id.toLocaleLowerCase().includes(value)).sort((a, b) => a.name.trim().localeCompare(b.name));
                  }

                  this.loading(false);
                })
              )
            : of([]).pipe(
              finalize(() => {
                this.loading(false);
              })
            )
        )
      ).subscribe((pageResult: PagedResultOfPharmacyDto) => {
        if (pageResult.data === undefined) {
          this.pharmaciesDataSource = new MatTableDataSource<PharmacyDto>();
          this.mainPaginator.length = 0;
        } else {
          this.setPharmacyDataSource(pageResult.data);
          this.setPaginatorData(pageResult);
          this.mainPaginator.firstPage();
        }
      });
  }

  page(e: PageEvent) {
    this.loadPharmacies(
      this.pharmaciesSearchControl.value || '',
      e.pageSize,
      e.pageIndex
    );
  }

  loadPharmacies(searchText: string, pageSize: number, page: number) {
    this.loading(true);
    this.pharmacyApi.get(pageSize, page, searchText).subscribe(
      (pageResult: PagedResultOfPharmacyDto) => {
        this.iteration = undefined;
        if (pageResult.totalCount === 0) {
          this.noPharmaciesOwned = true;
          this.router.navigate([`/${APP_ROUTES.joinRpm}`, 'false']);
        }
        this.setPharmacyDataSource(pageResult.data);
        this.setPaginatorData(pageResult);

        this.remeberSelection = true;
        this.loading(false);
      },
      (e: HttpErrorResponse) => {
        this.loading(false);
        if (e.status === 404) {
          this.noPharmaciesOwned = true;
          this.router.navigateByUrl(APP_ROUTES.joinRpm);
        } else { throw e; }
      }
    );
  }

  private setPharmacyDataSource(data: PharmacyDto[]) {
    this.pharmaciesDataSource = new MatTableDataSource<PharmacyDto>(data);
  }
  private setPaginatorData(pageResult: PagedResultOfPharmacyDto) {
    this.mainPaginator.length = pageResult.totalCount;
  }

  choosenPharmacy(pharmacy: any) {
    this.selectedPharmacy = pharmacy;
    this.iteration = undefined;
  }

  search = false;
  showPreSearchBar() {
    return this.search === false;
  }
  initiateSearch() {
    this.search = true;
  }
  showSearchBar() {
    return this.search === true;
  }
  endSearch() {
    this.search = false;

    if (this.selectedPharmacy != null && this.salesOrganizationIds !== undefined) {
      this.selectedPharmacy.salesOrganizationIds = this.salesOrganizationIds.filter(
        x => x.name.toLocaleLowerCase() || x.id.toLocaleLowerCase()).sort((a, b) => a.name.trim().localeCompare(b.name));
    }
    this.pharmaciesSearchControl.reset();

    if (this.pharmaciesSearchSubscription$) {
      this.pharmaciesSearchSubscription$.unsubscribe();
    }

    this.cdr.detectChanges();

    this.loadPharmacies('', 9, 0);
    this.initSearch();
    this.screenSizeService.onResize$.subscribe(x => {
      setTimeout(() => {
        this.size = x;
        this.resizeGrid();
      });
    });
  }
  searchPlaceholder: string = Strings.SearchPlaceholder;

  choosePharmacy(
    choosenPharmacy: PharmacyDto,
    salesOrganization: SalesOrganizationDto
  ) {
    //TODO refactor for full pharmacyDto object
    if (!choosenPharmacy.hasRKMHAssigned) {
      this.router.navigateByUrl(APP_ROUTES.joinRpm);
    } else {
      var pharmacy = new SelectedPharmacy();
      pharmacy.customerId = Number.parseInt(choosenPharmacy.customerId);
      pharmacy.salesOrganization = salesOrganization;
      pharmacy.name = choosenPharmacy.name;

      this.loading(true);
      this.session.set(pharmacy)
        .then(() => {

          this.appComponent.trackPharmcy(pharmacy);
          this.router.navigateByUrl(APP_ROUTES.dashboard);
        })
        .finally(() => this.loading(false));
    }
  }

  private resizeGrid() {
    switch (this.size) {
      case SCREEN_SIZE.XS:
      case SCREEN_SIZE.SM: {
        this.gridOptions.gridType = GridType.VerticalFixed;
        this.gridOptions.setGridSize = true;
        this.gridOptions.maxCols = 10;
        this.gridOptions.minCols = 1;
        break;
      }
      case SCREEN_SIZE.MD: {
        this.gridOptions.gridType = GridType.VerticalFixed;
        this.gridOptions.setGridSize = true;
        this.gridOptions.maxCols = 5;
        this.gridOptions.minCols = 2;
        break;
      }

      case SCREEN_SIZE.LG: {
        this.gridOptions.gridType = GridType.Fit;
        this.gridOptions.setGridSize = false;
        this.gridOptions.maxCols = 3;
        this.gridOptions.minCols = 3;
        break;
      }

      case SCREEN_SIZE.XL: {
        this.gridOptions.gridType = GridType.Fit;
        this.gridOptions.setGridSize = false;
        this.gridOptions.maxCols = 3;
        this.gridOptions.minCols = 3;
        break;
      }
    }

    if (this.gridOptions.api) {
      this.gridOptions.api.optionsChanged();
      this.gridOptions.api.resize();
    }
  }

  gridOptions: GridsterConfig = {
    draggable: { enabled: true, ignoreContent: true },
    pushItems: true,
    resizable: { enabled: false },
    minItemRows: 1,
    minItemCols: 1,
    maxItemCols: 2,
    maxItemRows: 1,
    minCols: 1,
    maxCols: 3,
    gridType: GridType.Fit,
    swap: true,
    compactType: CompactType.CompactUpAndLeft,
    mobileBreakpoint: 768,
    keepFixedHeightInMobile: true,
    fixedRowHeight: 170,
    margin: 21
  };
}
