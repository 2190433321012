import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'grouponFilterTranslator'})
export class GrouponFilterTranslator implements PipeTransform {
  transform(value: any): any {
    
    switch(value) {
        case "failedFinished":
            return 'Zakończone niepowodzeniem';
        case "participate":
            return 'Uczestniczysz';  
        case "active":
            return 'Aktywne';
        case "inactive":
            return 'Nieaktywne';
        case "allCompleted":
            return 'Wszystkie zakończone'
        case "mineThisWeek":
            return 'Nowe w tym tygodniu';
        case "all":
            return 'Wszystkie';  
        case "successfulFinished":
            return 'Zakończone powodzeniem';
        default:
          return 'Wszystkie';
      }
}
}