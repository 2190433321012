import { PipeTransform, Pipe } from '@angular/core';
import { GrouponRealizationStatus } from 'src/app/services/service-proxies/api';


@Pipe({ name: 'realizationStatusTranslate' })
export class RealizationStatusTranslate implements PipeTransform {
  transform(value: any): string {

    switch (value) {
      case GrouponRealizationStatus.Failed:
      case "1":
        return "niepowodzenie"
      case GrouponRealizationStatus.Succeed:
      case "0":
        return "powodzenie"
    }
  }
}