import { Component, OnInit, Input, Injector, ViewChild, AfterViewInit } from '@angular/core';
import { moveItemInArray, CdkDragDrop, CdkDropListGroup, CdkDropList, CdkDragMove, CdkDrag } from '@angular/cdk/drag-drop';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SlideConfigurationDto, ImageDto } from 'src/app/services/service-proxies/api';
import { ViewportRuler } from '@angular/cdk/overlay';

@Component({
  selector: 'slide-configuration',
  templateUrl: './slide-configuration.component.html',
  styleUrls: ['./slide-configuration.component.scss']
})
export class SlideConfigurationComponent extends ApplicationComponent implements OnInit, AfterViewInit {


  @Input() slides: SlideConfigurationDto[];

  @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  constructor(injector: Injector, private viewportRuler: ViewportRuler) {
    super(injector);
    this.target = null;
    this.source = null;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  delete(idx) {
    this.yesNoDialogAnswer("", "Czy na pewno usunąć baner?").subscribe(y => {
      if (y) {
        this.slides.splice(idx, 1);
      }
    });
  }

  addNew() {
    this.slides.push(
      new SlideConfigurationDto({
        image: new ImageDto({ "fhd": "", "mobile": "" }),
        order: this.slides.length + 1,
        enabled: true
      }));
  }

  addImage(files, idx: number, mode: 'FHD' | 'MB') {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.notification.warn("Niedozwolony format pliku.");
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (mode == 'FHD')
        this.slides[idx].image.fhd = reader.result.toString();
      else if (mode == 'MB')
        this.slides[idx].image.mobile = reader.result.toString();
    }
  }

  // src(fileName) {
  //   return "/assets/img/slider/" + fileName;
  // }

  // slideExists(filename: string): Observable<boolean> {
  //   return this.httpClient.get("/assets/img/slider/" + filename)
  //     .pipe(
  //       map(response => {
  //         return true;
  //       }),
  //       catchError(error => {
  //         return of(false);
  //       })
  //     );
  // }

  dropListDropped(event: CdkDragDrop<HTMLElement[]>) {
    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.slides, this.sourceIndex, this.targetIndex);
      this.slides.forEach((v, i) => v.order = i + 1)
    }

  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}