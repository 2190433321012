import { TreeNode } from './tree-node';
import { FlatNode } from './flat-node';

export function transformer(node: TreeNode, level: number) {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  export function hasChild(_: number, node: FlatNode) {
    return node.expandable;
  };