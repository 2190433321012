import { Pipe, PipeTransform } from '@angular/core';
import { GrouponType } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'grouponTypeTranslate' })
export class GrouponTypeTranslate implements PipeTransform {

  transform(value: GrouponType): string {
    switch (value) {
      case GrouponType.Default:
        return 'zwykły';
      case GrouponType.Mixed:
        return 'mieszany';
      case GrouponType.Package:
        return 'pakietowy';
    }
  }
}