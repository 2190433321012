import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL } from './service-proxies/api';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpClientService extends HttpClient { 

  public constructor(@Inject(API_BASE_URL) private baseUrl: string, handler: HttpHandler) {
    super(handler);
  }

  public get<T>(url: string, options?: Object): Observable<any> {
    url = this.baseUrl + url;
    return super.get<T>(url, options);
  }

  public post<T>(url: string, body: any, options?: Object): Observable<any> {
    url = this.baseUrl + url;
    return super.post<T>(url, body, options);
  }

  public put<T>(url: string, body: T, options?: Object): Observable<any> {
    url = this.baseUrl + url;
    return super.put<T>(url,  body, options);
  }

  public delete<T>(url: string, options?: Object): Observable<any> {
    url = this.baseUrl + url;
    return super.delete<T>(url, options);
  }

}
