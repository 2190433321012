import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FlatNode } from 'src/app/models/tree/flat-node';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { transformer, hasChild } from 'src/app/models/tree/functions';
import { FailedActionsStatisticsDto, API_Dashboard, StatisticsActionDto } from 'src/app/services/service-proxies/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-failed-actions',
  templateUrl: './failed-actions.component.html',
  styleUrls: ['./failed-actions.component.scss']
})
export class FailedActionsComponent extends ApplicationComponent implements OnInit {

  stats$: Observable<FailedActionsStatisticsDto>;
  showActionsList = false;

  constructor(injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  ngOnInit() {

    if (!this.userSessionService.currentValue || !this.userSessionService.currentValue.selectedPharmacy) {
      return;
    }

    this.loadingLocal(true,'failedActionsSpinner');
    this.stats$ = this.api.getFailedActions(this.userSessionService.currentValue.selectedPharmacy.customerId).pipe(
      tap(x => this.loading(false, 'failedActionsSpinner'))
    );
  }
  navigateToDeclaration(action: StatisticsActionDto) {
    this.router.navigate(['/', 'grouponDeclaration', action.grouponId]);
  }

  showGroupons(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "filter": "failedFinished"
      }
  };
  this.router.navigate(["groupons"], navigationExtras);
  }
}
