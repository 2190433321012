import { Pipe, PipeTransform } from '@angular/core';
import { GrouponSettlementingType } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'settlementingUnit' })
export class SettlementingUnitPipe implements PipeTransform {

  transform(value: GrouponSettlementingType ): string {
    switch (value) {
      case GrouponSettlementingType.Count:
        return 'szt.';
      case GrouponSettlementingType.Value:
        return 'zł';
    }
  }
}