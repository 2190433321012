import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from '../application.Component';
import { ActivatedRoute, Router } from '@angular/router';
import { YourPurchaseDto, API_YourPurchases, GrouponThresholdType} from 'src/app/services/service-proxies/api';
import { OrderStatusTranslator } from '../shared/transform-pipes/translate-order-status';

@Component({
  selector: 'app-your-shopping',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends ApplicationComponent implements OnInit {

  constructor(injector: Injector, private route: ActivatedRoute, router: Router, private api: API_YourPurchases) {
    super(injector);
    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => this.size = x);
      });

    this.route.queryParams.subscribe(params => {
      this.threshold = params['threshold']
      this.product = new YourPurchaseDto();
      this.product.grouponThresholdType = params['grouponThresholdType']
      this.product.bloz = params['bloz']
      this.product.name = params['name']
      this.product.producer = params['producer']
      this.product.grouponName = params['grouponName']
      this.product.grouponStatus = params['grouponStatus']
      this.product.grouponRealizationStatus = params['grouponRealizationStatus']
      this.product.grouponParticipate = params['grouponParticipate']
      this.product.orderStatus = params['orderStatus']
      this.product.price = params['price']
      this.product.realizationDate = params['realizationDate']
      this.product.whValue = params['whValue']
      this.product.confirmedQuantity = params['confirmedQuantity']
      this.product.declarationDate = params['declarationDate']
      this.product.positionQuantity = params['positionQuantity']
    });
  }

  productName: string;
  product: YourPurchaseDto;
  threshold: number;
  priceAfterDiscounts: number;
  declarationTotal: number;
  realizationTotal: number;
  defaultEmptyDate: string = "-";
  pricePrognosed: number;

  ngOnInit() {
    const discount = (Number(this.product.whValue) + Number(this.threshold));
    this.pricePrognosed = this.product.price * (1.00 - discount / 100.00);
    if(this.product.grouponThresholdType==GrouponThresholdType.Amount){
    this.pricePrognosed =  this.product.price - discount;
    }
    this.priceAfterDiscounts = parseFloat(this.pricePrognosed.toFixed(2));
    const declarationAmount = (this.pricePrognosed ? this.pricePrognosed : 0) * (this.product.positionQuantity ? this.product.positionQuantity : 0);
    this.declarationTotal = parseFloat(declarationAmount.toFixed(2));
    const realizationAmount = (this.pricePrognosed ? this.pricePrognosed : 0) * (this.product.confirmedQuantity ? this.product.confirmedQuantity : 0);
    this.realizationTotal = parseFloat(realizationAmount.toFixed(2));
  }



}
