import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FlatNode } from 'src/app/models/tree/flat-node';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { transformer, hasChild } from 'src/app/models/tree/functions';
import { API_Dashboard, SuccessfulActionsStatisticsDto, StatisticsActionDto } from 'src/app/services/service-proxies/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-accomplished-actions',
  templateUrl: './accomplished-actions.component.html',
  styleUrls: ['./accomplished-actions.component.scss']
})
export class AccomplishedActionsComponent extends ApplicationComponent implements OnInit {

  stats$: Observable<SuccessfulActionsStatisticsDto>;
  showActionsList = false;

  constructor(injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  ngOnInit() { 

    if (!this.userSessionService.currentValue || !this.userSessionService.currentValue.selectedPharmacy) {
      return;
    }

    this.loadingLocal(true,'accomplishedActionsSpinner');
    this.stats$ = this.api.getSuccessfulActions(this.userSessionService.currentValue.selectedPharmacy.customerId)
      .pipe(tap(x=> this.loadingLocal(false, 'accomplishedActionsSpinner') ));    
  }
  
  navigateToDeclaration(action: StatisticsActionDto) {
    this.router.navigate(['/', 'grouponDeclaration', action.grouponId]);
  }

  showGroupons(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "filter": "successfulFinished"
      }
  };
  this.router.navigate(["groupons"], navigationExtras);
  }
}
