import { Component, OnInit, Injector, ViewChild, AfterViewInit, AfterViewChecked, ChangeDetectorRef, PipeTransform, Pipe, ViewEncapsulation } from '@angular/core';
import { ApplicationComponent } from '../application.Component';
import { MatSelectionList, MatListOption } from "@angular/material/list";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { Strings } from 'src/app/models/Strings';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { API_YourPurchases, SortYourPurchases, YourPurchaseDto, FilterYourPurchases, GrouponProductThresholdDto, OrderConfirmationStatus } from 'src/app/services/service-proxies/api';
import { UserSessionService } from 'src/app/services/user-session-service.service';
import { FilterYourPurchasesPipe } from '../shared/transform-pipes/filter-YourPurchases';
import { FilterPurchasesTranslator } from '../shared/transform-pipes/translate-yourPurchases';
import { GrouponStatusTranslate } from '../shared/transform-pipes/groupon-status-translate';
import { SortYourPurchasesPipe } from '../shared/transform-pipes/sort-YourPurchases';
import { SortPurchasesTranslator } from '../shared/transform-pipes/translate-sort-yourPurchases';
import { SelectionModel } from '@angular/cdk/collections';
import { OrderStatusTranslator } from '../shared/transform-pipes/translate-order-status';
import { observe } from 'rxjs-observe';

const instance = { filter: "All", sort: "ProductAlphabetically", searchText: "" };
const { observables, proxy } = observe(instance);

@Component({
  selector: 'app-your-shopping',
  templateUrl: './your-shopping.component.html',
  styleUrls: ['./your-shopping.component.scss']
})
export class YourShoppingComponent extends ApplicationComponent implements OnInit {
  @ViewChild('mainPaginator', { static: true }) mainPaginator: MatPaginator;
  @ViewChild('participantsPaginator', { static: false }) participantsPaginator: MatPaginator;
  @ViewChild('productsPaginator', { static: false }) productsPaginator: MatPaginator;
  @ViewChild('mainSort', { static: true }) sort: MatSort;
  @ViewChild('positionsSort', { static: false }) positionsSort: MatSort;

  constructor(injector: Injector,
    private route: ActivatedRoute,
    router: Router,
    private api: API_YourPurchases,
    private session: UserSessionService,) {
    super(injector);
    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => this.size = x);
      });
    this.filter = null;
    this.sortRecords = null;
  }


 // selectedSort = new FormControl('product-alphabetically'); //sort
  yourPurchasesSearchControl = new FormControl();
  displayedColumns: string[] = ['bloz', 'productName', 'producer', 'orderStatus', 'groupon', 'grouponEndDate', 'actions'];
  dataSource = new MatTableDataSource<YourPurchaseDto>();
  filter: any;
  sortRecords: any;
  grouponName: string = "";
  producer: string = "";
  productName: string = "";
  selection = new SelectionModel<YourPurchaseDto>(true, []);

  searchPlaceholder: string = Strings.SearchPlaceholder;

  public filterYourPurchasesArray = FilterYourPurchases
  public sortPurchases = SortYourPurchases

  ngOnInit() {

    //reset filters
    const previousUrl = this.previousUrlSerice.getPreviousUrl();
    if (previousUrl === undefined || previousUrl.startsWith('/product') === true || previousUrl.startsWith('/yourPurchases') === true) {
      this.filter = FilterYourPurchases[proxy.filter] === 0 ? 'All' : FilterYourPurchases[proxy.filter];
      this.sortRecords = SortYourPurchases[proxy.sort] === 0 ? 'GrouponEndDateDescending' : SortYourPurchases[proxy.sort];
      this.yourPurchasesSearchControl.setValue(proxy.searchText);
    } else {
      this.filter = 'All';
      this.sortRecords = 'GrouponEndDateDescending';
      this.yourPurchasesSearchControl.setValue('');
    }

    this.loadYourPurchases();
  }

  loadYourPurchases() {
    this.loading(true);
    this.selection.clear();

    proxy.filter = FilterYourPurchases[this.filter];
    proxy.sort = SortYourPurchases[this.sortRecords];

    this.api.getAll(this.session.currentValue.selectedPharmacy.customerId.toString(), "", this.filter, this.sortRecords).subscribe(data => {
      this.dataSource = new MatTableDataSource<YourPurchaseDto>(data);
      this.dataSource.paginator = this.mainPaginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);

      this.applyFilter(this.yourPurchasesSearchControl.value);
      this.loading(false);
    },
      () => this.loading(false));

  }
  getProperty = (obj, path) => (path.split('.').reduce((o, p) => o && o[p], obj));


  submit(searchtxt: string) {
    this.applyFilter(searchtxt);
  }


  applyFilter(filterValue: string) {
    proxy.searchText = filterValue.trim().toLowerCase()
    this.dataSource.filter = proxy.searchText;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.selection.selected.forEach(s => {
      if (this.dataSource.filteredData.findIndex(g => g.bloz == s.bloz) == -1) {
        this.selection.deselect(s);
      }
    });
  }

  clear() {
    this.filter = "All";
    this.sortRecords = "GrouponEndDateDescending";
    this.loadYourPurchases();
  }

  navigate(element: YourPurchaseDto) {

    let threshold = this.getCurrentThreshold(element)

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "bloz": element.bloz.toString(),
        "name": element.name,
        "producer": element.producer,
        "status": element.orderStatus,
        "grouponName": element.grouponName,
        "grouponStatus": element.grouponStatus,
        "grouponRealizationStatus": element.grouponRealizationStatus,
        "grouponParticipate": element.grouponParticipate,
        "orderStatus": element.orderStatus,
        "price": element.price,
        "realizationDate": element.realizationDate,
        "whValue": element.whValue,
        "declarationDate": element.declarationDate,
        "confirmedQuantity": element.confirmedQuantity,
        "positionQuantity": element.positionQuantity,
        "threshold": threshold,
        "grouponThresholdType": element.grouponThresholdType,

      }
    };
    this.router.navigate(["product"], navigationExtras);
  }
  private getCurrentThreshold(purchase: YourPurchaseDto): number {

    let d = 0;
    if (purchase.globalThresholds) {
      let t = purchase.globalThresholds.find(tr => tr.isCurrent);
      if (t)
        d += t.discountPercent;
    }

    if (purchase.thresholds) {
      let t = purchase.thresholds.find(t => t.isCurrent);
      if (t) {
        d += t.discountPercent;
      }
    }
    return d;
  }

  showGrouponDetails(grouponId: number) {
    this.router.navigate(['/', 'grouponDeclaration', grouponId]);
  }

}
