import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { ContractAccomplishmentPeriod, OfficeRankingDto, API_Dashboard } from 'src/app/services/service-proxies/api';
import { MatRadioChange } from '@angular/material/radio';
import { UserSessionService } from 'src/app/services/user-session-service.service';

@Component({
  selector: 'app-pharmacy-rankings',
  templateUrl: './pharmacy-rankings.component.html',
  styleUrls: ['./pharmacy-rankings.component.scss']
})
export class PharmacyRankingsComponent extends ApplicationComponent implements OnInit {

  ContractAccomplishmentPeriodEnum = ContractAccomplishmentPeriod;

  period: ContractAccomplishmentPeriod = ContractAccomplishmentPeriod.Current;
  data: OfficeRankingDto;

  constructor(injector: Injector, private api: API_Dashboard, public session: UserSessionService) {
    super(injector);
  }

  ngOnInit() {
    this.loadingLocal(true, 'pharmacyRankingsSpinner');
    this.session.current.subscribe(s => {
      if (s && s.selectedPharmacy)
        this.loadData(s.selectedPharmacy.customerId);
    });    
  }

  periodChange(change: MatRadioChange) {
    this.period = change.value;
    this.loadingLocal(true, 'pharmacyRankingsSpinner');
    this.loadData(this.session.currentValue.selectedPharmacy.customerId);
  }
  
  loadData(customerId : number) {
    if(this.session.currentValue)
    this.api
      .getPharmacyOfficeRanking(customerId, this.period)
      .subscribe((r : OfficeRankingDto) => {
        this.data = r;
        this.loadingLocal(false, 'pharmacyRankingsSpinner');
      });       
  }
}
