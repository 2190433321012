import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { UserSessionService } from './user-session-service.service';
import { API_User, UserDto } from './service-proxies/api';
import { UserSession } from '../models/UserSession';
import { APP_ROUTES } from '../components/APP_ROUTES';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    
    pharmacyContextIsSet: boolean;
    
    constructor(public user: UserService,
        private api_user: API_User,
        private session: UserSessionService,
        public router: Router) {
        
        this.ensurePharmacyContextIsSet();
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const rkmhOnly = route.data["rkmhOnly"];
        const pharmacyContextRequired = route.data["pharmacyContextRequired"];

        

        //await this.ensureIsAuthenticated();

        if (this.session && this.session.currentValue && !this.session.currentValue.user.hasPharmaciesWithRkmhAssigned) {
            if (route.routeConfig.path.indexOf(APP_ROUTES.joinRpm) > -1){
                return true;
            }
            this.router.navigate([`/${APP_ROUTES.joinRpm}`]);
            return false;
        }
        
        if (pharmacyContextRequired) {
            if (!this.pharmacyContextIsSet) {
                this.router.navigate([`/${APP_ROUTES.selector}`]);
                return false;
            }
        }

        if (rkmhOnly) {
            return await this.checkIfIsRkmh();
        }
        return true;
    }

     ensurePharmacyContextIsSet() {    
         this.session.current.subscribe(r => {
             this.pharmacyContextIsSet = r && r.selectedPharmacy != null;
         });
    }

    ensureIsAuthenticated() : Promise<UserSession> {
        return this.api_user.isAuthenticated()
            .toPromise()
            .then((result: boolean) => {
                if (!result) {
                    this.session.clear();
                    this.user.logIn();  
                    
                } else {
                    if (this.session.currentValue == null)
                        return this.session.set(null);
                }
            });
    }

     async checkIfIsRkmh(): Promise<boolean> {
       var result = await this.api_user.get().toPromise().then((x: UserDto) => {
                return x.isRkmh;
            });
       return result;
    }

}