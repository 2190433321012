import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'filterTranslator'})
export class FilterPurchasesTranslator implements PipeTransform {
  transform(value: any): any {
    
    switch(value) {
        case "All":
            return 'Wszystkie';
        case "GrouponSuccessfulFinished":
            return 'Grupon: Zakończony powodzeniem';  
        case "GrouponFailedFinished":
            return 'Grupon: Zakończony niepowodzeniem';
        case "GrouponActive":
            return 'Grupon: Aktywny';
        case "ProductDeclaration":
            return 'Produkt: Deklaracja'
        case "ProductFailure":
            return 'Produkt: Niepowodzenie';
        case "ProductSentToRealization":
            return 'Produkt: Wysłano do realizacji';  
        case "ProductInRealization":
            return 'Produkt: W trakcie realizacji';
        case "ProductParttiallyRealization":
            return 'Produkt: Zrealizowano częściowo';
        case "ProductFinishedRealization":
            return 'Zrealizowano';
        case "ProductRejected":
            return 'Odrzucono';
        default:
          return 'Wszystkie';
      }
}
}
