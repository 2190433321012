import {
  Component,
  OnInit,
  Optional,
  Host,
  Input,
  Injector,
  ChangeDetectorRef
} from "@angular/core";
import { SatPopover } from "@ncstate/sat-popover";
import {
  GrouponThresholdDto,
  GrouponSettlementingType,
  GrouponThresholdType
} from "src/app/services/service-proxies/api";
import { filter } from "rxjs/operators";
import { ApplicationComponent } from "../../application.Component";

@Component({
  selector: "app-thresholds-inline-editor",
  templateUrl: "./thresholds-inline-editor.component.html",
  styleUrls: ["./thresholds-inline-editor.component.scss"]
})
export class ThresholdsInlineEditorComponent extends ApplicationComponent
  implements OnInit {
  @Input()
  get value(): GrouponThresholdDto[] {
    return this._value;
  }
  set value(x: GrouponThresholdDto[]) {
    this.val = [...(this._value = (x && [...x.map(x => x.clone())]) || [])];
  }

  @Input()
  settlementingType: GrouponSettlementingType = GrouponSettlementingType.Count;

  @Input()
  thresholdType: GrouponThresholdType = GrouponThresholdType.Percent;

  @Input()
  price : number = 0;


  @Input()
  readonly: boolean;

  private _value: GrouponThresholdDto[] = [];
  val: GrouponThresholdDto[] = [];

  private ChangeDetectorRef: ChangeDetectorRef;

  constructor(
    @Optional() @Host() public popover: SatPopover,
    injector: Injector
  ) {
    super(injector);   
  }
  ngOnInit() {
    if (this.popover) {
      this.popover.closed
        .pipe(filter(val => val == null))
        .subscribe(
          () =>  (this.val = [...this.value.map(x => x.clone())] || [])
        );
    }
  }

  onSubmit() {
    if (this.popover) {
      
      if (!this.isValid()) {
        this.notification.error('Nieprawidłowo zdefiniowane progi rabatowe: progi rabatowe nie mogą być identyczne.');
        return;
      }

      this.val = this.val.sort((a, b) => a.discountThreshold > b.discountThreshold ? 1 : -1);
      this.popover.close(this.val);
    }
  }

  isValid() {
    let lookup = {};
    let result = [];

    for (let i = 0; i < this.val.length; i++) {
      this.val[i]["key"] = this.val[i].discountPercent + this.val[i].discountThreshold;
        if(!lookup[this.val[i]["key"]]){
            lookup[this.val[i]["key"]] = true;
            result.push(this.val[i]);
        }
    }
    return this.val.length == result.length;
  }  

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }

  addThreshold() {
    if (!this.val) {
      this.val = new Array<GrouponThresholdDto>();      
    }
    else {
      this.val = this.val.sort((a, b) => a.discountThreshold > b.discountThreshold ? 1 : -1);
    }

    this.val.push(
      new GrouponThresholdDto({
        discountPercent: 0,
        discountThreshold: 0,
        isCurrent: false,
        completionPercentage: 0,
        declared: 0
      })
    );
  }

  removeThreshold(threshold: GrouponThresholdDto) {
    this.yesNoDialog(
      "Uwaga!",
      "Czy na pewno chcesz usunąć wybrany próg rabatowy?"
    )
      .afterClosed()
      .subscribe(yes => {
        if (yes) {
          this.val.forEach((item, index) => {
            if (item === threshold) {
              this.val.splice(index, 1);
            }
          });
        }
      });
  }

  scroll(el: HTMLElement) {
    setTimeout(() => el.scrollIntoView({ behavior: "smooth" }));
  }

  valuechange(obj : GrouponThresholdDto, property, event : KeyboardEvent, type) {
    if (event && event.currentTarget['valueAsNumber'] < 0) {
      obj[property] = 0;
    } else if (parseInt(event.currentTarget['valueAsNumber']) > 100 && property == "discountPercent"&& type == GrouponThresholdType.Percent ) {
      obj.discountPercent = 100;   
      return;
    } else if (property == "discountThreshold") {
      obj[property] = parseInt(event.currentTarget['valueAsNumber']);  
      return;
    }    
  }

  get units(): string {
    switch (this.settlementingType) {
      case GrouponSettlementingType.Count:
        return "szt.";
      case GrouponSettlementingType.Value:
        return "zł";
    }
  }

  get thresholdsUnit(): string{
    switch(this.thresholdType){
      case GrouponThresholdType.Percent:
        return "%";
      case GrouponThresholdType.Amount:
        return "zł";

    }
  }

  get maxValue(): number{
    switch(this.thresholdType){
      case GrouponThresholdType.Percent:
        return 100;
      case GrouponThresholdType.Amount:
        return null;

    }
  }
}
