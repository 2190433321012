import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Pipe({name: 'filterYourPurchasesPipe'})
export class FilterYourPurchasesPipe implements PipeTransform {
  transform(data: Object) {
    const keys = Object.keys(data);
    return keys.slice(keys.length / 2);
  }
}