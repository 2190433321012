import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from 'src/app/components/application.Component';
import { TreeNode } from 'src/app/models/tree/tree-node';
import { FlatNode } from 'src/app/models/tree/flat-node';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { transformer, hasChild } from 'src/app/models/tree/functions';

@Component({
  selector: 'app-pharmacy-active-actions',
  templateUrl: './pharmacy-active-actions.component.html',
  styleUrls: ['./pharmacy-active-actions.component.scss']
})
export class PharmacyActiveActionsComponent extends ApplicationComponent implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    
    this.dataSource.data = [
      {
        name: 'Lista grouponów',
        children: [
          { name: 'Bogactwo część pierwsza' },
          { name: 'Bogactwo część główna' },          
        ]
      }];
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = hasChild
}
