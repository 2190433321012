
export const environment = {
  production: false,
  MATOMO_URL: "//stats.aptelink.pl/",
  SITE_ID: 12,
  COOKIE_DOMAIN: "*.rpmonline.aptenavi.pl",
  DOMAINS: ['*.rpmonline.aptenavi.pl'],
  GOOGLE_ANALITICS_ID: "UA-92269793-4",
  USER_ID: "",
  PAYER_ID: "",
  PHARMACY_ID: "",
  COMPANY_ID: "",
  GA_ENABLED: true,
  MA_ENABLED: true,
  PREVIOUS_URL: null,
  NU: null,
  oidc: {
    issuer: 'https://adfsaptekaad.aptekaad.neuca.pl/adfs',
    metadataAddress: 'https://aptekatstadfs.aptelink.pl/adfs/.well-known/openid-configuration',
    tokenEndpoint: 'https://aptekatstadfs.aptelink.pl/adfs/oauth2/token/',
    userinfoEndpoint: 'https://aptekatstadfs.aptelink.pl/adfs/userinfo',
    clientId: '13a0c509-a94a-4f43-9bba-39990da6238d',
    redirectUri: window.location.origin,
    responseType: 'code',
    scope: 'email profile openid ',
    requireHttps: false,
    showDebugInformation: true,
    useSilentRefresh: false,
    preserveRequestedRoute: true,
    disablePKCE: false,
  }
};