import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackType } from '../models/notifications/snack-type';
import { SuccesComponent } from '../components/shared/notifications/succes/succes.component';
import { WarnComponent } from '../components/shared/notifications/warn/warn.component';
import { ErrorComponent } from '../components/shared/notifications/error/error.component';
import { InfoComponent } from '../components/shared/notifications/info/info.component';

@Injectable({
  providedIn: 'root'
})

export class SnackBarService {
  constructor(private snack: MatSnackBar) {
  }

  info(message: string, duration?: number) {
    const config = this.getMatSnackBarConfig(duration, SnackType.Success);
    config.data = { msg: message };
    this.snack.openFromComponent(InfoComponent, config);
  }

  succes(message: string, duration?: number) {
    const config = this.getMatSnackBarConfig(duration, SnackType.Success);
    config.data = { msg: message };
    this.snack.openFromComponent(SuccesComponent, config);
  }

  warn(message: string, duration?: number) {
    const config = this.getMatSnackBarConfig(duration, SnackType.Warn);
    config.data = { msg: message };
    this.snack.openFromComponent(WarnComponent, config);
  }

  error(message: string, duration?: number) {
    const config = this.getMatSnackBarConfig(duration, SnackType.Error);
    config.data = { msg: message };
    this.snack.openFromComponent(ErrorComponent, config);
  }

  private getMatSnackBarConfig(duration?: number, snackType?: SnackType): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.duration = duration || 3000;
    config.panelClass = [this.getStyleClassFor(snackType)];
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    return config;
  }

  private getStyleClassFor(t: SnackType): string {
    switch (t) {
      case SnackType.Info:
        return 'snack-info';
      case SnackType.Success:
        return 'snack-success';
      case SnackType.Warn:
        return 'snack-warn';
      case SnackType.Error:
        return 'snack-error';
    }
  }
}