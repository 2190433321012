import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterRkmhPanel } from 'src/app/services/service-proxies/api';

@Pipe({ name: 'filterRkmhPanelName' })
export class FilterRkmhPanelName implements PipeTransform {
  transform(value: FilterRkmhPanel): any {

    switch (value) {
      case FilterRkmhPanel.All:
        return 'Wszystkie';
      case FilterRkmhPanel.Draft:
        return 'Szkic';
      case FilterRkmhPanel.Inactive:
        return 'Nieaktywny';
      case FilterRkmhPanel.Active:
        return 'Aktywny';
      case FilterRkmhPanel.Finished:
        return 'Zakończony';
      case FilterRkmhPanel.FinishedSuccess:
        return 'Zakończony powodzeniem';
      case FilterRkmhPanel.FinishedFail:
        return 'Zakończony niepowodzeniem';
      case FilterRkmhPanel.FinishedAll:
        return 'Wszystkie zakończone';
      default:
        return 'Nieznany filtr';
    }
  }
}