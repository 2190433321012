import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PharmacySelectorComponent } from './components/pharmacy-selector/pharmacy-selector.component';
import { JoinRpmComponent } from './components/join-rpm/join-rpm.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { APP_ROUTES } from './components/APP_ROUTES';
import { GrouponListComponent } from './components/groupons/groupon-list/groupon-list.component';
import { GrouponDeclarationComponent } from './components/groupons/groupon-declaration/grouponDeclaration.component';
import { RkmhConfigurationComponent } from './components/admin/rkmh-configuration/rkmh-configuration.component';
import { GrouponEditorComponent } from './components/groupons/groupon-editor/groupon-editor.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotFound404Component } from './components/shared/errors/not-found404/not-found404.component';
import { YourShoppingComponent } from './components/your-shopping/your-shopping.component';
import { ProductComponent } from './components/product/product.component';
import { SliderComponent } from './components/dashboard/slider/slider.component';
import { SliderConfigurationComponent } from './components/admin/slider-configuration/slider-configuration.component';
import { AuthenticationGuard } from './services/auth/oidc/authentication.guard';

const routesArray: Routes = [
  {
    path: APP_ROUTES.selector,
    component: PharmacySelectorComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: false },
  },
  {
    path: APP_ROUTES.dashboard,
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: true },
  },
  {
    path: APP_ROUTES.joinRpm,
    component: JoinRpmComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: false },
  },
  {
    path: APP_ROUTES.joinRpmWoPharmacies,
    component: JoinRpmComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: false },
  },
  {
    path: APP_ROUTES.groupons,
    component: GrouponListComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: true },
  },
  {
    path: APP_ROUTES.yourPurchases,
    component: YourShoppingComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: true },
  },
  {
    path: APP_ROUTES.product,
    component: ProductComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: true },
  },
  {
    path: APP_ROUTES.grouponDeclaration,
    component: GrouponDeclarationComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: true },
  },
  {
    path: APP_ROUTES.grouponEditor,
    component: GrouponEditorComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { rkmhOnly: true, pharmacyContextRequired: false },
  },
  {
    path: '',
    component: PharmacySelectorComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { pharmacyContextRequired: false },
  },
  {
    path: APP_ROUTES.rkmhConfiguration,
    component: RkmhConfigurationComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { rkmhOnly: true, pharmacyContextRequired: false },
  },
  {
    path: APP_ROUTES.slider,
    component: SliderConfigurationComponent,
    canActivate: [AuthenticationGuard, AuthGuard],
    data: { rkmhOnly: true, pharmacyContextRequired: false },
  },

  //404 error handling
  { path: '**', component: NotFound404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routesArray, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
