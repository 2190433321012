import { Component, OnInit, ViewChild, Injector, AfterViewInit, Input, ElementRef } from "@angular/core";
import { APP_ROUTES } from "../../APP_ROUTES";
import { ApplicationComponent } from "../../application.Component";
import { GrouponService } from "../../../services/groupon.service";
import { UserSessionService } from "../../../services/user-session-service.service";
import { Strings } from "src/app/models/Strings";
import {
  API_GrouponCards,
  GrouponCardDto,
  GrouponThresholdDto,
  GrouponDto,
  GrouponStatus,
  GrouponProductThresholdDto,
  GrouponType,
  GrouponThresholdType,
  GrouponRealizationStatus,
  SortYourGroupons
} from "src/app/services/service-proxies/api";
import { GridsterConfig, GridType, CompactType } from "angular-gridster2";
import { SCREEN_SIZE } from 'src/app/models/screen-size';
import { Subscription, from, Observable, zip, Subject } from 'rxjs';
import { publishReplay, refCount, map, switchMap } from 'rxjs/operators';
import { observe } from "rxjs-observe";
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { SortGrouponsTranslator } from '../../shared/transform-pipes/translate-sort-yourGroupons';
import { stringify } from 'querystring';
import { PropertyRead } from '@angular/compiler';
import { IterablePipe } from '../../shared/transform-pipes/groupons-filter-pipe';
import { GrouponFilterTranslator } from '../../shared/transform-pipes/groupon-filter-translator-pipe';
import * as moment from 'moment';
import { Console } from "console";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";



const instance = { key: "active", value: true, searchText: "", sort: "IdDescending"};
const { observables, proxy } = observe(instance);

@Component({
  selector: "app-groupons",
  templateUrl: "./groupon-list.component.html",
  styleUrls: ["./groupon-list.component.scss"],
  providers: [GrouponService]
})

export class GrouponListComponent extends ApplicationComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSort', { static: true }) sort: MatSort;
  GrouponStatus = GrouponStatus;

  constructor(
    injector: Injector,
    private api: API_GrouponCards,
    private session: UserSessionService,
    private route: ActivatedRoute

  ) {
    super(injector);
    this.filterText = null;

    this.searchText = null;
    this.filterGroupons = null;

    this.route.queryParams.subscribe(params => {

      if (params['filter'] != null && params['filter'] == "failedFinished") {
        proxy.key = 'failedFinished'
      } else if (params['filter'] != null && params['filter'] == "participate") {
        proxy.key = 'participate'
      } else if (params['filter'] != null && params['filter'] == "active") {
        proxy.key = 'active'
      } else if (params['filter'] != null && params['filter'] == "inactive") {
        proxy.key = 'inactive'
      } else if (params['filter'] != null && params['filter'] == "allCompleted") {
        proxy.key = 'allCompleted'
      } else if (params['filter'] != null && params['filter'] == "mineThisWeek") {
        proxy.key = 'mineThisWeek'
      } else if (params['filter'] != null && params['filter'] == "all") {
        proxy.key = 'all'
      } else if (params['filter'] != null && params['filter'] == "successfulFinished") {
        proxy.key = 'successfulFinished'
      } else {
        proxy.key = 'active'
      }

    });
    this.sortRecords = null;

  }



  filterValues: { [key: string]: boolean } = { "all": true, "participate": true, "active": true, "inactive": true, "allCompleted": true, "mineThisWeek": true, "successfulFinished": true, "failedFinished": true };

  pharmacyId: number;
  dataSource = new MatTableDataSource<GrouponCardDto>();
  filterText: string;
  searchText: string = null;
  filterGroupons: any;
  sortRecords: any;

  // Search variables
  public grouponFilter(item): boolean {
    if (!this) return true;
    if (!this.filterText) return true;

    const dataStr = JSON.stringify(item).toLowerCase();
    return dataStr.indexOf(this.filterText) != -1;
  }
  search = !null;

  searchPlaceholder: string = Strings.SearchPlaceholder;
  public sortGroupons = SortYourGroupons;
  ngOnInit() {
    this.session.current.subscribe(u => {
      try {
        this.pharmacyId = u.selectedPharmacy.customerId;
      } catch (error) {
        this.pharmacyId = null;
      }
    });

    this.searchText = proxy.searchText;
    this.filterGroupons = proxy.key
    this.sortRecords =  'GrouponAlphabetically';
    this.loadGroupons(proxy.key);

    this.applyFilter(this.searchText)

  }

  ngAfterViewInit() {
    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => {
          this.size = x;
          this.resizeGrid();
        });

      });
  }

  navigateTo(value) {
   // console.log(value)
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "filter": value
      }
    };
    this.router.navigate(["groupons"], navigationExtras);
  }

  sortingBy(value) {
    this.applySorting(value);
  }

  loadGroupons(event) {
    if (this.pharmacyId === null) {
      this.router.navigateByUrl(APP_ROUTES.selector);
    }

    this.loading(true);

    proxy.key = event;
    proxy.value = true;
    proxy.sort = SortYourGroupons[this.sortRecords];
    this.api.post(this.pharmacyId, this.sortRecords, this.filterGroupons).subscribe(
      
      (data: GrouponCardDto[]) => {
        data.forEach(d => {
          d["rows"] = 1;
          d["cols"] = 1;
        });
        

        this.dataSource = new MatTableDataSource(data);
        //this.dataSource.sort = this.sort;

/*         this.dataSource = new MatTableDataSource(data.sort(
          (a, b) => {
            let firstStart = new Date(a.startDate.toDate())
            let secondStart = new Date(b.startDate.toDate())
            let firstEnd = new Date(a.endDate.toDate())
            let secondEnd = new Date(b.endDate.toDate())
            if (firstStart.getFullYear() - secondStart.getFullYear() == 0 && firstStart.getMonth() - secondStart.getMonth()== 0 && firstStart.getDate()== secondStart.getDate()) {
              return +new Date(firstEnd.getFullYear(),firstEnd.getMonth()-1, firstEnd.getDate()) - +new Date(secondEnd.getFullYear(),secondEnd.getMonth()-1, secondEnd.getDate())
            } else {
                return +new Date(firstStart.getFullYear(),firstStart.getMonth()-1, firstStart.getDate()) - +new Date(secondStart.getFullYear(),secondStart.getMonth()-1, secondStart.getDate())
            }
          }
        )); */


        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.dataSource.filterPredicate = this.filter;
        this.applyFilter(proxy.searchText.trim().toLowerCase())
        this.applySorting(proxy.sort)
        this.loading(false);
      },
      () => {
        this.notification.error("Niestety nie udało się pobrać Twoich gruponów.")
        this.loading(false);
      }
    );
  }

  filter(data: GrouponCardDto, filterText) {

    const dataStr = JSON.stringify(data).toLowerCase();
    let found = dataStr.indexOf(filterText) != -1;

    return found;
  }

  applyFilter(filterValue: string) {

    proxy.searchText = filterValue;
    this.dataSource.filter = proxy.searchText.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySorting(sort: string){
    proxy.sort = sort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    
  }

  showGrouponDetails(grouponId: number) {
    this.router.navigateByUrl("grouponDeclaration/" + grouponId);
  }

  showPreSearchBar = function () {
    return this.search == null;
  };
  initiateSearch = function () {
    this.search = "";
  };
  showSearchBar = function () {
    return this.search != null;
  };

  endSearch() {

    this.search = null;
    this.loadGroupons(proxy.key);

    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => {
          this.size = x;
          this.resizeGrid();
        });

      });
  };

  submit = function () {
    console.error("Search function not yet implemented");
  };

  private getNextThreshold(groupon: GrouponCardDto): GrouponThresholdDto {
    if (groupon && groupon.grouponThresholds) {
      let idx = groupon.grouponThresholds.findIndex(t => t.isCurrent);
      if (idx > -1) {
        //is there a next threshold?
        if (groupon.grouponThresholds[idx + 1]) {
          return groupon.grouponThresholds[idx + 1];
        } else {
          //its actually last threshold
          return groupon.grouponThresholds[idx];
        }
      }
      //first threshold not reached yet
      return groupon.grouponThresholds[0];
    } else return null;
  }

  isFailedFinished(groupon: GrouponCardDto) {
    return (groupon.status == GrouponStatus.Archival || groupon.status == GrouponStatus.Finished) && (groupon.realizationStatus != null && groupon.realizationStatus == 1);
  }

  isFinished(groupon: GrouponCardDto) {
    return (groupon.status == GrouponStatus.Archival || groupon.status == GrouponStatus.Finished) && (groupon.realizationStatus == undefined || groupon.realizationStatus == null);
  }


  isActive(groupon: GrouponCardDto) {
    return groupon.status == GrouponStatus.Active && groupon.participantStatus == 1;
  }

  isActiveAndParticipate(groupon: GrouponCardDto) {
    return groupon.status == GrouponStatus.Active && groupon.participantStatus == 0;
  }

  isInActive(groupon: GrouponCardDto) {
    return groupon.status == GrouponStatus.Inactive;
  }

  isSuccessfulFinished(groupon: GrouponCardDto) {
    return (groupon.status == GrouponStatus.Archival || groupon.status == GrouponStatus.Finished) && (groupon.realizationStatus != null && groupon.realizationStatus == 0);
  }

  isFiltered(groupon: GrouponCardDto) {
    return this.dataSource.filteredData.some(f => f == groupon);
  }

  maxThreshold(groupon: GrouponCardDto): number {
    let productThresholds = new Array<GrouponProductThresholdDto>();
    groupon.products.forEach(p => {
      if (p.thresholds)
        productThresholds.push(...p.thresholds)
    });
    switch (groupon.type) {
      case GrouponType.Default:
        return Math.max(...productThresholds.map(t => t.discountPercent), 0);
      case GrouponType.Mixed:
      case GrouponType.Package:
        return Math.max(...groupon.grouponThresholds.map(t => t.discountPercent), 0) + Math.max(...productThresholds.map(t => t.discountPercent), 0);
      default: return null;
    }
  }

  getClassForFilter(key: string) {

    switch (key) {

      case 'participate':
        return 'color-primary';

      case 'active':
      case 'successfulFinished':
        return 'color-green';

      case 'failedFinished':
        return 'color-red';

      default:
        return 'text-secondStartary';
    }

  }

  private resizeGrid() {
    switch (this.size) {
      case SCREEN_SIZE.XS:
      case SCREEN_SIZE.SM: {

        this.gridOptions.maxCols = 1;
        this.gridOptions.minCols = 1;
        break;
      }
      case SCREEN_SIZE.MD: {
        this.gridOptions.maxCols = 2;
        this.gridOptions.minCols = 2;

        break;
      }

      case SCREEN_SIZE.LG: {
        this.gridOptions.maxCols = 3;
        this.gridOptions.minCols = 3;
        break;
      }

      case SCREEN_SIZE.XL: {
        this.gridOptions.maxCols = 4;
        this.gridOptions.minCols = 4;
        break;
      }
    }
    if (this.gridOptions.api) {
      this.gridOptions.api.resize();
      this.gridOptions.api.optionsChanged();
    }
  }

  gridOptions: GridsterConfig = {
    draggable: { enabled: false },
    pushItems: true,
    resizable: { enabled: false },
    minItemRows: 1,
    minItemCols: 1,
    maxItemCols: 1,
    maxItemRows: 2,
    maxCols: 4,
    minCols: 4,
    fixedRowHeight: 320,
    gridType: GridType.VerticalFixed,
    setGridSize: true,
    keepFixedHeightInMobile: true,
    keepFixedWidthInMobile: false,
    swap: true,
    mobileBreakpoint: 768,
    compactType: CompactType.CompactUpAndLeft,
  };
}