import { Component, OnInit, ViewChild, Type, ElementRef, AfterViewInit, Injector } from '@angular/core';
import { SamDragAndDropGridComponent } from '@sam-1994/ngx-drag-and-drop-grid'
import { UserActiveActionsComponent } from './dashboard-tiles/user-active-actions/user-active-actions.component';
import { PharmacyActiveActionsComponent } from './dashboard-tiles/pharmacy-active-actions/pharmacy-active-actions.component';
import { ContractAccomplishmentComponent } from './dashboard-tiles/contract-accomplishment/contract-accomplishment.component';
import { PharmacyRankingsComponent } from './dashboard-tiles/pharmacy-rankings/pharmacy-rankings.component';
import { AllActiveActionsComponent } from './dashboard-tiles/all-active-actions/all-active-actions.component';
import { AccomplishedActionsComponent } from './dashboard-tiles/accomplished-actions/accomplished-actions.component';
import { FailedActionsComponent } from './dashboard-tiles/failed-actions/failed-actions.component';
import { GridsterConfig, GridsterItem, GridsterItemComponent, CompactType, GridType, DisplayGrid } from 'angular-gridster2';
import { IDashboardTileComponent, IDashboardTileAvailableComponent } from 'src/app/models/DashboardTile';
import { ApplicationComponent } from '../application.Component';
import { API_User, API_Dashboard, UserDashboardConfigurationDto, DashboardTileDto } from 'src/app/services/service-proxies/api';
import { UserSessionService } from 'src/app/services/user-session-service.service';
import { map } from 'rxjs/operators';
import { TYPED_NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { runInThisContext } from 'vm';
import { SCREEN_SIZE } from 'src/app/models/screen-size';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ApplicationComponent implements OnInit, AfterViewInit {

  public components: Array<IDashboardTileComponent> = new Array<IDashboardTileComponent>();

  constructor(injector: Injector,
    private api: API_Dashboard,
    private session: UserSessionService) {
    super(injector);
  }


  ngOnInit(): void {
    this.session.currentValue.user.email
    this.loading(true);
    this.api.getConfiguration(this.session.currentValue.user.email).subscribe(c => {
      this.loading(false);
      this.buildDashboardTiles(c);
    });
  }

  ngAfterViewInit() {
    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => {
          this.size = x;
          this.resizeGrid();
        });

      });
  }

  savePreferences() {
    this.yesNoDialogAnswer("", "Czy na pewno chcesz zapisać bieżący układ strony?")
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          this.api.saveConfiguration(this.session.currentValue.user.email, this.serialize())
            .subscribe(res => {
              this.loading(false);
              this.notification.succes("Zapisano układ strony.");
            }, e => {
              this.loading(false);
              this.notification.error("Nie udało się zapisać układy strony.");
            });
        }
      });
  }

  redirectToGrouponList() {
    this.router.navigateByUrl("groupons");
  }

  redirectToYourPurchases() {
    this.router.navigateByUrl("yourPurchases");
  }

  revertToDefault() {
    this.yesNoDialogAnswer("", "Czy na pewno chcesz przywrócić domyślny układ strony?")
      .subscribe(yes => {
        if (yes) {
          this.loading(true);
          this.api.restoreDefaultConfiguration(this.session.currentValue.user.email)
            .subscribe(res => {
              this.buildDashboardTiles(res);
              this.loading(false);
              this.notification.succes("Przywrócono domyślny układ strony.");
            }, e => {
              this.loading(false);
              this.notification.error("Nie udało się przywrócić domyślnego układy strony.");
            });
        }
      });
  }

  private serialize(): DashboardTileDto[] {
    return this.components.map(c => {
      let t = new DashboardTileDto();
      t.init(c);
      return t;
    });
  }

  private buildDashboardTiles(c: UserDashboardConfigurationDto) {
    this.components = new Array<IDashboardTileComponent>();
    c.dashboardTiles.forEach(tile => {
      this.dashboardComponentsCollection.forEach(availableComponent => {
        if (tile.name === availableComponent.name) {
          let t: IDashboardTileComponent = {
            componentType: availableComponent.componentType,
            name: tile.name,
            x: tile.x,
            y: tile.y,
            rows: tile.rows,
            cols: tile.cols
          };
          this.components.push(t);
        }
      })
    });
  }

  private resizeGrid() {
    switch (this.size) {
      case SCREEN_SIZE.XS:
      case SCREEN_SIZE.SM: {
        this.gridOptions.gridType = GridType.VerticalFixed;
        this.gridOptions.maxRow = 6;
        this.gridOptions.maxCols = 6;
        this.gridOptions.fixedRowHeight = 400;
        this.gridOptions.fixedColWidth = 420;
        this.gridOptions.keepFixedWidthInMobile = false;
        this.gridOptions.keepFixedHeightInMobile = true;
        break;
      }
      case SCREEN_SIZE.MD: {
        this.gridOptions.gridType = GridType.VerticalFixed;
        this.gridOptions.maxRow = 6;
        this.gridOptions.maxCols = 6;
        this.gridOptions.fixedRowHeight = 300;
        this.gridOptions.fixedColWidth = 220;
        break;
      }
      case SCREEN_SIZE.LG: {
        this.gridOptions.gridType = GridType.VerticalFixed;
        this.gridOptions.maxRow = 6;
        this.gridOptions.maxCols = 6;
        this.gridOptions.fixedRowHeight = 400;
        this.gridOptions.fixedColWidth = 350;
        break;
      }

      case SCREEN_SIZE.XL: {
        this.gridOptions.gridType = GridType.Fit;
        this.gridOptions.maxCols = 6;
        this.gridOptions.maxRows = 2;
        this.gridOptions.draggable = { enabled: true }
        this.gridOptions.useTransformPositioning = true;
        this.gridOptions.pushItems = true;
        this.gridOptions.draggable.enabled = true;
        this.gridOptions.draggable.ignoreContent = false;
        this.gridOptions.pushDirections.north = true;
        this.gridOptions.pushDirections.east = true;
        this.gridOptions.pushDirections.south = true;
        this.gridOptions.pushDirections.west = true;

        break;
      }
    }

    if (this.gridOptions.api) {
      this.gridOptions.api.optionsChanged();
      this.gridOptions.api.resize();
    }
  }

  gridOptions: GridsterConfig = {
    draggable: { enabled: true, ignoreContent: false },
    pushItems: true,
    resizable: { enabled: false },
    minItemRows: 1,
    minItemCols: 1,
    maxItemCols: 2,
    maxItemRows: 6,
    maxCols: 6,
    maxRows: 2,
    gridType: GridType.Fit,
    swap: true,
    mobileBreakpoint: 566,
    margin: 30,
    //setGridSize: true,
    compactType: CompactType.CompactUpAndLeft,
    pushDirections: { north: true, east: true, south: true, west: true }

  };

  //wszystkie komponenty z ponizszej tablicy sa ladowane dynamicznie, nalezy je dodac do entryComponents w NgModule
  protected dashboardComponentsCollection: Array<IDashboardTileAvailableComponent> = [
    { componentType: PharmacyRankingsComponent, name: "PharmacyRankingsComponent" },
    { componentType: UserActiveActionsComponent, name: "UserActiveActionsComponent" },
    //{ componentType: PharmacyActiveActionsComponent, name: "PharmacyActiveActionsComponent"},
    { componentType: AllActiveActionsComponent, name: "AllActiveActionsComponent" },
    { componentType: AccomplishedActionsComponent, name: "AccomplishedActionsComponent" },
    { componentType: FailedActionsComponent, name: "FailedActionsComponent" },
    { componentType: ContractAccomplishmentComponent, name: "ContractAccomplishmentComponent" },
  ];
}
