import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppInitializeService {

    constructor(private configurationService: ConfigurationService, private httpClient: HttpClient) {
    }

    OnPreload(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.configurationService.loadConfig()
            .then(() =>  resolve(true))                   
            .catch(e => {
                reject(e);
            });
        });
    }    
}
