import { Component, OnInit, ViewChild, Injector, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES } from '../../APP_ROUTES';
import { ApplicationComponent } from '../../application.Component';
import { UserSessionService } from '../../../services/user-session-service.service';
import { API_GrouponDeclarations, API_BusinessConditions, GrouponDeclarationDto, GrouponDeclarationChangeSalesOrganizationDto, GrouponProductDto, GrouponDeclarationPositionDto, GrouponThresholdDto, GrouponType, GrouponProductThresholdDto, GrouponStatus, ParticipantStatus, GrouponSettlementingType, PharmacyDto, GrouponThresholdType, GrouponDeclarationPharmacyDto } from 'src/app/services/service-proxies/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Strings } from 'src/app/models/Strings';
import { BehaviorSubject } from 'rxjs';
import { HttpStatusCode } from 'src/app/models/infrastructure/HttpStatusCode';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SatPopover } from '@ncstate/sat-popover';
import { Injectable, Provider } from '@angular/core'
import { DeclarationPharmaciesSelectorComponent, DialogData } from '../declaration-pharmacies-selector/declaration-pharmacies-selector.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { CountdownConfig } from 'ngx-countdown';
@Injectable()
export class MyLogger {

    public log(logMsg: string) {
        console.log(logMsg);
    }
}

@Component({
    selector: 'app-groupons',
    templateUrl: './groupon-declaration.component.html',
    styleUrls: ['./groupon-declaration.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class GrouponDeclarationComponent extends ApplicationComponent implements OnInit, AfterContentChecked {

    @ViewChild('positionsPaginator', { static: true }) positionsPaginator: MatPaginator;
    @ViewChild('fusPopover', null) fusPopover: SatPopover;

    GrouponType = GrouponType;
    GrouponStatus = GrouponStatus;
    GrouponSettlementingType = GrouponSettlementingType;
    GrouponThresholdType = GrouponThresholdType;

    constructor(injector: Injector,
        private api: API_GrouponDeclarations,
        private route: ActivatedRoute,
        private session: UserSessionService, private cdr: ChangeDetectorRef,
        private apiBusinessConditions: API_BusinessConditions
    ) {
        super(injector);


        this.pharmacyId = this.session.currentValue.selectedPharmacy.customerId;
        this.salesOrganizationId = this.session.currentValue.selectedPharmacy.salesOrganization.id;
        this.screenSizeService.onResize$
            .subscribe(x => {
                setTimeout(() => this.size = x);
            });

    }

    pharmacyId: number;
    salesOrganizationId: string;
    declaration: GrouponDeclarationDto = new GrouponDeclarationDto();
    totalSum: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    expandedElement: GrouponDeclarationPositionDto | null;
    expandedProduct: GrouponProductDto | null;
    producer: string;
    hasChanged = false;
    savedWHValue: number = null;
    totalDiscount: number;
    daysToFinishAction: any = new DaysFormat;
    today = moment(new Date()).locale("PL");
    isPretiumFarmCompany: boolean;
    grouponPharmaciesCount: number = 0;
    currentPharmacies: GrouponDeclarationPharmacyDto[];

    positionsDataSource: MatTableDataSource<GrouponDeclarationPositionDto> = new MatTableDataSource();
    get positionsDataSourceColumns(): string[] {
        switch (this.declaration.grouponType) {
            case GrouponType.Default:
                return ['bloz', 'name', 'netPrice', 'discount', 'priceAfterMaxDiscount', 'priceAfterDiscount', 'minimum', 'details', 'declaredQuantity'];
            case GrouponType.Package:
                return ['bloz', 'name', 'netPrice', 'discount', 'priceAfterMaxDiscount', 'priceAfterDiscount', 'multiplicity', 'details', 'declaredQuantity'];
            case GrouponType.Mixed:
                return ['bloz', 'name', 'netPrice', 'discount', 'priceAfterMaxDiscount', 'priceAfterDiscount', 'minimum', 'multiplicity', 'details', 'declaredQuantity'];
        }
    }

    globalThresholdDataSource: MatTableDataSource<GrouponThresholdDto> = new MatTableDataSource();
    thresholdDataSource: MatTableDataSource<GrouponThresholdDto> = new MatTableDataSource();
    get thresholdDataSourceColumns(): string[] {
        return ['discountThreshold', 'discountPercent', 'declared', 'completionPercentage', 'totalDiscountPercent'];
    }

    ngOnInit(): void {
        this.loading(true);
        const grouponId = parseInt(this.route.snapshot.paramMap.get('grouponId'));
        this.session.current.subscribe(u => {
            try {
                this.pharmacyId = u.selectedPharmacy.customerId;
                this.salesOrganizationId = u.selectedPharmacy.salesOrganization.id;
                this.isPretiumFarmCompany = this.salesOrganizationId === 'GKPR'; // PR01 / GK Pretium

            } catch (error) {

                this.pharmacyId = null;
            }
        });

        this.apiBusinessConditions.get(this.session.currentValue.selectedPharmacy.customerId.toString()).subscribe(data => {
            this.savedWHValue = data.whValue
        },
            (error) => {
                this.savedWHValue = null;
            });

        this.loadGrouponDeclaration(grouponId, this.pharmacyId, this.salesOrganizationId);
    }

    loadGrouponDeclaration(grouponId: number, pharmacyId: number, salesOrganizationId: string) {

        if (!this.pharmacyId || !salesOrganizationId || !grouponId) {
            this.loading(false);
            this.router.navigateByUrl(APP_ROUTES.selector);
        }

        this.loading(true);
        //console.log('load: ' + pharmacyId);
        this.api.get(grouponId, pharmacyId.toString(), salesOrganizationId).subscribe((data: GrouponDeclarationDto) => {
            this.setGrouponDeclaration(data);
            this.loading(false);
        },
            (e: HttpErrorResponse) => {
                this.loading(false);
                if (e.status === HttpStatusCode.Conflict) {
                    this.yesNoDialog("", `Deklaracja w tym gruponie została już złożona w hurtowni - ${e["response"]}.
                    Czy chcesz przenieść deklarację do hurtowni "${this.salesOrganizationId}"?`, "", "Powrót do wyboru apteki i hurtowni")
                        .afterClosed()
                        .subscribe(answeredYes => {
                            if (answeredYes) {
                                let request = new GrouponDeclarationChangeSalesOrganizationDto();
                                request.customerId = this.pharmacyId.toString();
                                request.grouponId = grouponId;
                                request.salesOrganizationId = this.salesOrganizationId;
                                this.changeDeclarationSalesOrganization(request);
                            }
                            else {
                                this.router.navigateByUrl(APP_ROUTES.selector);
                            }
                        })
                }
                else if (e.status === HttpStatusCode.Forbidden) {
                    this.router.navigateByUrl(APP_ROUTES.groupons);
                }
            });
    }


    private setGrouponDeclaration(data: GrouponDeclarationDto) {
        this.declaration = data;
        this.declaration['totalSum'] = 0;

        this.declaration.positions.forEach(p => {
            p["prevDeclared"] = p.declaredQuantity;
        });

        this.positionsDataSource = new MatTableDataSource(this.declaration.positions);

        this.positionsDataSource.paginator = this.positionsPaginator;

        if (this.declaration.grouponType !== GrouponType.Default) {
            this.globalThresholdDataSource = new MatTableDataSource(this.declaration.grouponThresholds);
        } else {
            this.globalThresholdDataSource.data = [];
        }

        this.updatePharmacies(this.declaration.grouponPharmacies);

        this.producer = this.getProducer();
        this.recalculate();
        this.hasChanged = false;
        this.calculateDate(this.declaration.endDate);
    }

    private getProducer(): string {
        return this.declaration.positions[0] != undefined ? this.declaration.positions[0].products[0].producer : "";
    }

    private getNextThreshold(): GrouponThresholdDto {
        if (this.declaration && this.declaration.grouponThresholds) {
            let idx = this.declaration.grouponThresholds.findIndex(t => t.isCurrent);
            if (idx > -1) {
                //is there a next threshold?
                if (this.declaration.grouponThresholds[idx + 1]) {
                    return this.declaration.grouponThresholds[idx + 1];
                }
                else { //its actually last threshold
                    return this.declaration.grouponThresholds[idx];
                }
            }
            //first threshold not reached yet
            return this.declaration.grouponThresholds[0];
        }
        else return null
    }

    maxThreshold(groupon: GrouponDeclarationDto): number {
        switch (groupon.grouponType) {
            case GrouponType.Default:
                let productThresholds = new Array<GrouponProductThresholdDto>();
                groupon.positions.forEach(p => p.products.forEach(pr => productThresholds.push(...pr.thresholds)));
                return Math.max(...productThresholds.map(t => t.discountPercent), 0);
            case GrouponType.Mixed:
            case GrouponType.Package:
                return Math.max(...groupon.grouponThresholds.map(t => t.discountPercent), 0);
            default: return null;
        }
    }

    changeDeclarationSalesOrganization(request: GrouponDeclarationChangeSalesOrganizationDto) {
        this.loading(true);
        this.api.changeSalesOrganization(request).subscribe(data => {
            this.setGrouponDeclaration(data);
            this.loading(false);
            this.notification.succes("Pomyślnie zmieniono hurtownię.");
        },
            () => {
                this.loading(false);
                this.notification.error("Nie udało się zmienić hurtowni")
            });
    }

    get saveAvailable(): boolean {
        return this.isEditable;
    }

    get saveAvailableWH(): boolean {
        return this.isEditableWH;
    }

    saveDeclaration() {

        if (!this.validateDeclaration()) {
            this.notification.error('Niepoprawna wartość deklaracji.');
            return;
        }

        this.declaration.grouponPharmacies = this.currentPharmacies;

        let isResignation = this.declaration.participantStatus == ParticipantStatus.Participate && this.declaration.positions.every(d => d.declaredQuantity <= 0)

        if (isResignation) {
            this.yesNoDialog("", "Wszystkie pozycje zostały wyzerowane. Czy na pewno chcesz zrezygnować z udziału w gruponie?")
                .afterClosed().subscribe(yes => {
                    if (yes) {
                        if (this.declaration['totalSum'] === 0.00) {
                            this.declaration.participantStatus = ParticipantStatus.NotParticipate;
                        }
                        this.save(true);
                    }
                });
        }
        else {
            this.yesNoDialog("", "Czy na pewno chcesz zapisać deklarację?").afterClosed().subscribe(yes => {
                if (yes) {
                    //console.log(this.declaration['totalSum']);
                    if (this.declaration['totalSum'] === 0.00) {
                        this.declaration.participantStatus = ParticipantStatus.NotParticipate;
                    }
                    this.save(false);
                }
            });
        }
    }

    resign() {
        this.yesNoDialog("", "Czy na pewno chcesz zrezygnować z udziału w gruponie?")
            .afterClosed().subscribe(yes => {
                if (yes) {
                    this.declaration.positions.forEach(p => p.declaredQuantity = 0);

                    if (this.declaration['totalSum'] === 0.00) {
                        this.declaration.participantStatus = ParticipantStatus.NotParticipate;
                    }
                    this.save(true);
                }
            });
    }

    private save(isResignation: boolean) {
        this.loading(true);
        const request = this.declaration.id == 0 ? this.api.post(this.declaration) : this.api.put(this.declaration);

        request.subscribe((dec: GrouponDeclarationDto) => {
            this.loading(false);
            this.setGrouponDeclaration(dec);
            this.notification.succes(isResignation ? "Zrezygnowano z udziału w gruponie." : "Pomyślnie zapisano deklarację.");
            this.hasChanged = false;
        },
            (e) => {
                this.loading(false);
                if (e.status == HttpStatusCode.Conflict) {
                    this.notification.warn("Nie można potwierdzić deklaracji z powodu zmian, które wystąpiły w gruponie.");
                    this.loadGrouponDeclaration(this.declaration.grouponId, this.pharmacyId, this.salesOrganizationId);
                }
                else {
                    this.notification.error("Nie udało się potwierdzić deklaracji.");
                }
            });
    }

    goToGrouponList() {
        if (this.hasChanged) {
            this.yesNoDialog('', 'Czy przejść do listy gruponów bez zapisu deklaracji?')
                .afterClosed().subscribe(yes => {
                    if (yes) {
                        this.location.back();
                    }
                });
        } else {
            this.location.back();
        }

    }
    private validateDeclaration(): boolean {

        switch (this.declaration.grouponType) {
            case GrouponType.Default:
            case GrouponType.Mixed:
                return !this.declaration.positions.some(
                    p => p.isActive && !p.isPackage && p.products.some(
                        pr => p.declaredQuantity > 0 && p.declaredQuantity < pr.multiplicity));
        }

        return true;
    }

    recalculate() {
        this.declaration["nextThreshold"] = this.getNextThreshold();
        // todo refactor
        let totalSum = 0.00;
        this.declaration.positions.forEach(p => {
            let lineTotal = 0.00;
            p.products.forEach(pr => {
                let discount = this.getDiscount(pr);
                let pricePrognosed = pr.price * (1.00 - discount / 100.00);
                if (this.declaration.grouponThresholdType == GrouponThresholdType.Amount) {
                    pricePrognosed = pr.price - discount;
                }
                pr['pricePrognosed'] = pricePrognosed = parseFloat(pricePrognosed.toFixed(2));
                pr['discount'] = discount = discount;
                lineTotal += (pricePrognosed ? pricePrognosed : 0) * (p.isPackage || this.declaration.grouponType == GrouponType.Package ? pr.multiplicity : 1);
            });

            p['positionTotal'] = (parseFloat(lineTotal.toFixed(2)) * p.declaredQuantity);
            p['positionTotalDisplayString'] = p['positionTotal'].toFixed(2);
            totalSum += p['positionTotal'] ? p['positionTotal'] : 0;
        });
        this.declaration['totalSum'] = totalSum.toFixed(2);
        this.totalSum.next(parseFloat(totalSum.toFixed(2)));
        this.totalDiscount = this.getTotalDiscount();
        this.hasChanged = true;
        // this.cdr.detectChanges();
    }
    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    getCurrentThreshold(pr: GrouponProductDto) {
        if (pr && pr.thresholds) {
            let t = pr.thresholds.find(t => t.isCurrent);
            if (t) return t.discountPercent
            else return 0;
        }
        else return 0;
    }

    getMaxThresholdsDiscountPercent(pr: GrouponProductDto) {

        if (pr && pr.thresholds) {
            let t = (pr.thresholds.find(t => t.discountPercent = Math.max.apply(Math, pr.thresholds.map(function (o) { return o.discountPercent; }))))
            if (t) return t.discountPercent
            else return 0;
        }
        else return 0;
    }


    getMinimumPricePrognosed(pr: GrouponProductDto) {
        switch (this.declaration.grouponThresholdType) {
            case GrouponThresholdType.Percent:
                return pr.price * (1.00 - this.getMaxTotalProductDiscount(pr) / 100.00);
            case GrouponThresholdType.Amount:
                return pr.price - this.getMaxTotalProductDiscount(pr);
            default:
                return 0;
        }
    }



    calculateAmount(pr: GrouponProductDto, percent: number) {
        switch (this.declaration.grouponThresholdType) {
            case GrouponThresholdType.Percent:
                return parseFloat((percent / 100.0 * pr.price).toFixed(2));
            case GrouponThresholdType.Amount:
                return percent;
            default:
                return 0;
        }
    }

    getDiscount(pr: GrouponProductDto) {
        switch (this.declaration.grouponType) {
            case GrouponType.Default:
                return parseFloat((this.getCurrentThreshold(pr) + this.declaration.whDiscount).toFixed(2));
            case GrouponType.Mixed:
            case GrouponType.Package:
                return parseFloat((this.getCurrentGrouponThreshold() + this.getCurrentThreshold(pr) + this.declaration.whDiscount).toFixed(2));
            default:
                return 0;
        }
    }

    getDiscountAmount(pr: GrouponProductDto) {
        switch (this.declaration.grouponThresholdType) {
            case GrouponThresholdType.Percent:
                return (1.00 - this.getDiscount(pr) / 100.0) * pr.price;
            case GrouponThresholdType.Amount:
                return pr.price - this.getDiscount(pr);
            default:
                return 0;
        }

    }

    isMaxDiscount(pr: GrouponProductDto) {
        if (this.getMaxTotalProductDiscount(pr) > this.getDiscount(pr))
            return false;
        return true;
    }

    getTotalDiscount() {
        let maxProductDiscount = Math.max(...this.declaration.positions.map(p => {
            return Math.max(...p.products.map(pr => this.getCurrentThreshold(pr)));
        }));
        return parseFloat((this.maxThreshold(this.declaration) + this.declaration.whDiscount).toFixed(2));
    }

    getMaxProductDiscount(pr: GrouponProductDto) {
        if (pr && pr.thresholds) {
            return Math.max(...pr.thresholds.map(t => t.discountPercent), 0);
        }
        else return 0;

    }

    getMaxTotalProductDiscount(pr: GrouponProductDto) {
        switch (this.declaration.grouponType) {
            case GrouponType.Default:
                return parseFloat((this.getMaxProductDiscount(pr) + this.declaration.whDiscount).toFixed(2));
            case GrouponType.Mixed:
            case GrouponType.Package:
                return parseFloat((this.getMaxProductDiscount(pr) + this.maxThreshold(this.declaration) + this.declaration.whDiscount).toFixed(2));
        }

    }


    private getCurrentGrouponThreshold() {
        if (this.declaration && this.declaration.grouponThresholds) {
            let t = [...this.declaration.grouponThresholds].find(t => t.isCurrent);
            if (t) return t.discountPercent
            else return 0;
        }
        else return 0;
    }

    getPackageUnavailabilityReason(position: GrouponDeclarationPositionDto) {
        if (!position.isActive) {
            return Strings.MaterialServiceIsInactive;
        }
    }

    updatedeclaredQuantity(position: GrouponDeclarationPositionDto, eventValue: number) {
        if (eventValue != undefined && eventValue > -1 && position.declaredQuantity != eventValue) {
            position.declaredQuantity = Math.round(eventValue);
            this.declaration.positions.forEach(p => {
                if (p != position) return;

                p.declaredQuantity = position.declaredQuantity;
                if (p.declaredQuantity < 0)
                    p.declaredQuantity = 0;
            });

        }
        this.recalculate();
    }

    openWhDiscountEditor() {
        if (this.isPretiumFarmCompany) {
            this.infoDialog('Rabat za warunki handlowe', 'Brak rabatu w przypadku spółki Pretium Farm - rabat jest uwzględniony w cenie.',);
        }
        else
            this.fusPopover.open();
    }

    updatewhDiscount(eventValue: number) {
        if (this.declaration && eventValue >= 0 && eventValue <= 100) {
            this.loading(true)
            if (this.savedWHValue === null || this.savedWHValue === undefined) {
                this.apiBusinessConditions.create(eventValue, this.session.currentValue.selectedPharmacy.customerId.toString(), this.declaration.grouponId).subscribe(data => {
                    this.savedWHValue = data.whValue;
                    this.loadGrouponDeclarationAfterWhChange(this.declaration.grouponId, this.pharmacyId.toString(), this.salesOrganizationId)
                })
            } else if (this.savedWHValue !== null && this.savedWHValue !== undefined && !isNaN(this.declaration.grouponId) && eventValue !== null && eventValue !== undefined) {
                this.apiBusinessConditions.put(eventValue, this.session.currentValue.selectedPharmacy.customerId.toString(), this.declaration.grouponId).subscribe(data => {
                    this.savedWHValue = data.whValue;
                    this.loadGrouponDeclarationAfterWhChange(this.declaration.grouponId, this.pharmacyId.toString(), this.salesOrganizationId)
                })
            }

        }
        this.recalculate();
    }

    loadGrouponDeclarationAfterWhChange(grouponId, pharmacyId, salesOrganizationId) {
        this.api.get(grouponId, pharmacyId.toString(), salesOrganizationId).subscribe((data: GrouponDeclarationDto) => {
            this.setGrouponDeclaration(data);
            this.notification.succes("Warunki handlowe zostały pomyślnie zaktualizowane we wszystkich przypisanych aktywnych deklaracjach.");
            this.loading(false);
        });
    }

    openDialog(): void {

        const dialogRef = this.dialog.open<DeclarationPharmaciesSelectorComponent, DialogData>(DeclarationPharmaciesSelectorComponent, {
            data: {
                grouponParticipants: this.declaration.grouponParticipants,
                value: this.currentPharmacies,
                pharmacyId: this.pharmacyId,
                salesOrganizationId: this.salesOrganizationId,
                declaration: this.declaration
            }
        });

        dialogRef.afterClosed().subscribe((result: GrouponDeclarationPharmacyDto[]) => {
            if (result) {
                this.updatePharmacies(result);
            }
            else {
                this.updatePharmacies(this.currentPharmacies);
               // console.log('reset');
            }
        });
    }

    updatePharmacies(pharmacies: GrouponDeclarationPharmacyDto[]) {
        if (pharmacies) {
            this.currentPharmacies = pharmacies;
            this.grouponPharmaciesCount = this.currentPharmacies.length;
            console.log('updatePharmacies', this.currentPharmacies);
        }
    }

    get isEditable(): boolean {
        if (this.declaration) {

            if (this.declaration.status == GrouponStatus.Active) {
                return true;
            }

            if (this.declaration.realizationStatus != null) {
                return false;
            }

            if (this.declaration.status == GrouponStatus.Finished && this.userSessionService.currentValue.user.isRkmh) {
                return true;
            }

            return false;
        }
    }

    get isAnyActive(): boolean {
        return this.declaration && this.declaration.positions &&
            this.declaration.positions.some(p => p.isActive);
    }

    get isEditableWH(): boolean {
        if (this.declaration.status == GrouponStatus.Active) {
            return true;
        }
        return false;
    }

    changeAllDeclarationsBy(val: number) {
        if (val) {
            this.declaration.positions.forEach(p => {
                if (!p.isActive) return;

                p.declaredQuantity += val;
                if (p.declaredQuantity < 0)
                    p.declaredQuantity = 0;
            });

            this.recalculate();
        }
    }

    setExpandedElement(position: GrouponDeclarationPositionDto | null, selected: GrouponProductDto | null) {
        this.expandedElement = position;
        this.expandedProduct = selected;



        if (position && selected) {
            this.thresholdDataSource.data = selected.thresholds;
        } else {
            let emptyArray: Array<GrouponProductThresholdDto> = new Array<GrouponProductThresholdDto>();
            this.thresholdDataSource.data = emptyArray
        }
    }

    getProgressColor(progress) {
        if (progress < 21) {
            return 'warn';
        } else if (progress > 70) {
            return 'primary';
        }
        else {
            return 'accent';
        }
    }

    isPreviousProductThresholdCompleted(p: GrouponProductDto, t: number) {
        let idx = p.thresholds.findIndex(x => x.discountThreshold == t);
        if (idx > 0) {
            return p.thresholds[idx - 1].completionPercentage === 100;
        }
        else if (idx == 0) //true for first row
            return true;
        else
            return false;
    }

    isPreviousGrouponThresholdCompleted(t: number) {
        let idx = this.declaration.grouponThresholds.findIndex(x => x.discountThreshold == t);
        if (idx > 0) {
            return this.declaration.grouponThresholds[idx - 1].completionPercentage === 100;
        }
        else if (idx == 0) //true for first row
            return true;
        else
            return false;
    }

    calculateDate(grouponDate: moment.Moment): DaysFormat {
        let grouponDateMoment = moment(new Date(grouponDate.toDate())).locale("PL");

        let left = moment.duration(grouponDateMoment.diff(this.today)).asSeconds();
        this.timerConfig.leftTime = left > 0 ? left : 0;

        let delta = left;
        let days1 = Math.floor(delta / 86400);
        delta -= days1 * 86400;

        // calculate (and subtract) whole hours
        let hours1 = Math.floor(delta / 3600) % 24;
        delta -= hours1 * 3600;

        // calculate (and subtract) whole minutes
        let minutes1 = Math.floor(delta / 60) % 60;
        delta -= minutes1 * 60;

        // what's left is seconds
        let seconds1 = delta % 60;

        this.daysToFinishAction.totalSeconds = left * 1000; //in miliseconds
        this.daysToFinishAction.days = days1;
        this.daysToFinishAction.hours = hours1;
        this.daysToFinishAction.minutes = minutes1;
        this.daysToFinishAction.seconds = seconds1.toFixed(0);
        return this.daysToFinishAction;
    }

    timerConfig: CountdownConfig = {
        leftTime: 0,
        format: 'dd:H:mm:ss',
        demand: false,
        prettyText: (text) => {
            var parts = text.split(":");
            return `                
                <span class="mx-1">
                    <span class="main">${this.timerConfig.leftTime == 0 ? 0 : parts[1]}</span>
                    <span class="secondary">godz.</span>
                </span>
                <span class="mx-1">
                    <span class="main">${parts[2]}</span><span class="secondary"> min</span>
                </span>
                <span class="mx-1">
                    <span class="main">${parts[3]}</span><span class="secondary"> s</span>
                </span>`;
        },
    }

}

export class DaysFormat {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    totalSeconds: number;
}
