import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Injector, Input } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngbmodule/material-carousel';
import { ApplicationComponent } from '../../application.Component';
import { SliderConfigurationDto, SlideConfigurationDto, API_Dashboard } from 'src/app/services/service-proxies/api';


@Component({
  selector: 'dashboard-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends ApplicationComponent implements OnInit, AfterViewInit {

  constructor(private cdr: ChangeDetectorRef, injector: Injector, private api: API_Dashboard) {
    super(injector);
  }

  @Input() configuration: SliderConfigurationDto;
  slides: SlideConfigurationDto[];

  ngOnInit() {
    if (!this.configuration) { //if config was not provided by parent component - read it from service (readonly mode)
      this.api.getSliderConfiguration().subscribe(c => {
        this.configuration = c;
        this.slides = c.slides.filter(s => s.enabled);

      });
    }
    else
      this.slides = this.configuration.slides.filter(s => s.enabled);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
