import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'src/app/models/infrastructure/HttpStatusCode';
import { SnackBarService } from '../snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/shared/dialogs/error-dialog/error-dialog.component';
import { Strings } from 'src/app/models/Strings';
import { runInThisContext } from 'vm';
import { observable } from 'rxjs';
import { ErrorComponent } from 'src/app/components/shared/notifications/error/error.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector, public dialog: MatDialog, private ngZone: NgZone) {
  }

  handleError(error: Error | HttpErrorResponse) {

    const notifier = this.injector.get<SnackBarService>(SnackBarService);
    let spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);

    if (error && error["status"] == HttpStatusCode.InternalServerError) {
      this.ngZone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: { title: Strings.WeAreSorry, errorMessage: Strings.InternalServerError }
        })
      });
    } else if (error && error["status"] == HttpStatusCode.BadRequest) {
      this.ngZone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: { title: Strings.WeAreSorry, errorMessage: Strings.BadRequest }
        })
      });
    }
    else if (error && error["status"] === HttpStatusCode.Empty) {

    }
    else {
      notifier.error(error.message);
    }
    // Always log errors       
    console.error(error);
    spinner.hide();
  }
}