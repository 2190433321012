import { Component, OnInit, Injector, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ApplicationComponent } from '../../application.Component';
import { UserService } from '../../../services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Strings } from 'src/app/models/Strings';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { API_Groupons, GrouponListDto, GrouponProductDto, MaterialStatus, GrouponStatus, FileResponse, GrouponRealizationStatus, FilterRkmhPanel } from 'src/app/services/service-proxies/api';
import { UserSessionService } from 'src/app/services/user-session-service.service';
import * as moment from "moment";
import { SelectionModel } from '@angular/cdk/collections';
import { observe } from 'rxjs-observe';
import { FormControl } from '@angular/forms';

const instance = { predefinedFilter: FilterRkmhPanel.All, searchText: "", includeBroken: false, createdByOthers: false };
const { observables, proxy } = observe(instance)

@Component({
  selector: 'app-rkmh-configuration',
  templateUrl: './rkmh-configuration.component.html',
  styleUrls: ['./rkmh-configuration.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RkmhConfigurationComponent extends ApplicationComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('mainPaginator', { static: true }) mainPaginator: MatPaginator;
  @ViewChild('productsPaginator', { static: false }) productsPaginator: MatPaginator;
  @ViewChild('mainSort', { static: true }) sort: MatSort;
  @ViewChild('positionsSort', { static: false }) positionsSort: MatSort;
  MaterialStatus = MaterialStatus;
  GrouponStatus = GrouponStatus;
  FilterRkmhPanel = FilterRkmhPanel;
  RealizationStatus = GrouponRealizationStatus;

  constructor(injector: Injector, private api: API_Groupons, private session: UserSessionService, private userService: UserService) {
    super(injector);
    this.includeBroken = false;
    this.createdByOthers = false;
    this.predefinedFilter = FilterRkmhPanel.All;

    this.screenSizeService.onResize$
      .subscribe(x => {
        setTimeout(() => this.size = x);
      });
  }

  displayedColumns = ["Indications", "name", "startDate", "endDate", "statusName", "owner.fullName", "Actions", "select"];
  displayedProductColumns = ["Indications", "lineNumber", "materialId", "name", "bloz", "producer"];
  dataSource: MatTableDataSource<GrouponListDto>;
  productsDataSource: MatTableDataSource<GrouponProductDto> = new MatTableDataSource();
  expandedElement: GrouponListDto | null;
  includeBroken: boolean;
  createdByOthers: boolean;
  predefinedFilter: FilterRkmhPanel = FilterRkmhPanel.All;
  selection = new SelectionModel<GrouponListDto>(true, []);
  searchPlaceholder: string = Strings.SearchPlaceholder;
  searchControl = new FormControl();
  importRkmhFile: File;


  filterOptions = [
    { value: FilterRkmhPanel.Draft, color: "" },
    { value: FilterRkmhPanel.Active, color: "green" },
    { value: FilterRkmhPanel.Inactive, color: "" },
    { value: FilterRkmhPanel.Finished, color: "" },
    { value: FilterRkmhPanel.FinishedSuccess, color: "green" },
    { value: FilterRkmhPanel.FinishedFail, color: "red" },
    { value: FilterRkmhPanel.FinishedAll, color: "" },
  ];

  ngOnInit() {
    this.searchControl.setValue(proxy.searchText);
    this.predefinedFilter = proxy.predefinedFilter;
    this.includeBroken = proxy.includeBroken;
    this.createdByOthers = proxy.createdByOthers;

  }
  ngAfterViewInit() {
    this.loadData();
  }
  ngAfterViewChecked() {
  }

  log(event) {
    console.log(event);
  }

  loadData() {
    this.loading(true);

    this.api.getForRkmh(this.session.currentValue.user.email, this.createdByOthers, this.includeBroken, this.predefinedFilter).subscribe(data => {
      this.loading(false);

      proxy.predefinedFilter = this.predefinedFilter;
      proxy.createdByOthers = this.createdByOthers;
      proxy.includeBroken = this.includeBroken;

      this.selection.clear();
      this.dataSource = new MatTableDataSource<GrouponListDto>(data);
      this.dataSource.paginator = this.mainPaginator;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data: any, filter) => {
        const dataStr = JSON.stringify(data).toLowerCase(); return dataStr.indexOf(filter) !== -1;
      };
      this.applyFilter(this.searchControl.value)
    },
      () => this.loading(false));
  }

  setExpandedElement(selected: GrouponListDto | null) {
    this.expandedElement = selected;
    if (selected) {
      this.productsDataSource = new MatTableDataSource<GrouponProductDto>(selected.products);
      this.productsDataSource.sort = this.positionsSort;
      this.productsDataSource.paginator = this.productsPaginator;

    } else {
      this.productsDataSource.data = [];
    }
  }

  clear() {
    this.includeBroken = proxy.includeBroken = false;
    this.predefinedFilter = proxy.predefinedFilter = FilterRkmhPanel.All;
    this.createdByOthers = proxy.createdByOthers = false;
    proxy.searchText = '';
    this.searchControl.setValue('');
    this.loadData();
  }

  submit(searchtxt: string) {
    this.applyFilter(searchtxt);
  }

  applyFilter(filterValue: string) {
    proxy.searchText = filterValue.trim().toLowerCase();
    this.dataSource.filter = proxy.searchText;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.selection.selected.forEach(s => {
      if (this.dataSource.filteredData.findIndex(g => g.id === s.id) === -1) {
        this.selection.deselect(s);
      }
    });
  }

  applyPositionsFilter(filterValue: string) {
    this.productsDataSource.filter = filterValue.trim().toLowerCase();
    if (this.productsDataSource.paginator) {
      this.productsDataSource.paginator.firstPage();
    }
  }

  getProperty = (obj, path) => (path.split('.').reduce((o, p) => o && o[p], obj));

  navigateTo(id: number) {
    if (this.size < this.SCREEN_SIZE.MD) {
      this.router.navigate(['/', 'grouponEditor', id]);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAnySelected() {
    return this.selection.selected && this.selection.selected.length > 0;
  }

  isAnyValidSelected() {
    return this.isAnySelected() &&
      this.selection.selected
        .findIndex(s =>
          s.status === GrouponStatus.Active ||
          s.status === GrouponStatus.Finished ||
          s.status === GrouponStatus.Archival) > -1;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  getReportFromSelected() {

    if (!this.isAnySelected()) {
      this.notification.warn("Zaznacz grupony, dla których ma być wygenerowany raport.");
      return;
    }

    if (!this.isAnyValidSelected()) {
      this.notification.warn("Raport można wygenerować tylko dla gruponów w statusie Aktywny, Zakończony oraz Archiwalny.");
      return;
    }

    this.loading(true);

    const selected = this.selection.selected.filter(s =>
      s.status === GrouponStatus.Active ||
      s.status === GrouponStatus.Finished ||
      s.status === GrouponStatus.Archival);

    this.api.getReport(selected.map(g => g.id)).subscribe(
      (fileResponse: FileResponse) => {

        if (selected.length > 1) {
          this.OpenFileFromStream(
            fileResponse.data,
            "application/zip",
            fileResponse.fileName || `raport_z_grouponow_${moment(moment.now()).format('YYYY-MM-DD')}.zip`);
        }
        else {
          this.OpenFileFromStream(
            fileResponse.data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            fileResponse.fileName || `${selected[0].name}_${moment(moment.now()).format('YYYY-MM-DD')}.xlsx`);
        }
        this.loading(false);
      }
    ),
      () => {
        this.loading(false);
        this.notification.error("Nie udało się wygenerować raportu");
      }
  }

  importRkmh(file: File) {
    this.importRkmhFile = file;
    if (!file.name.endsWith('.xls') && !file.name.endsWith('.xlsx')) {
      this.importRkmhFile = null;
      this.notification.error('Nieprawidłowy format pliku');
      return;
    }
    if (file) {
      this.yesNoDialogAnswer(
        '',
        'Czy na pewno chcesz zaktualizować przypisania RKMH do Aptek?'
      ).subscribe(yes => {
        this.loading(true);
        if (yes) {
          this.userService
            .ImportRkmh(file)
            .subscribe(
              () => {
                this.loading(false);
                this.notification.succes('Zaktualizowano przypisania RKMH do Aptek.');
              },
              () => {
                this.loading(false);
                this.notification.error('Nie udało się zaktualizować przypisania RKMH do Aptek.');
              }
            );
        }
      });
    }
    this.loading(false);
  }

}
