import { PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Pipe({ name: 'grouponStatusTranslate' })
export class GrouponStatusTranslate implements PipeTransform {
    transform(value: any): any {

        switch (value) {

            case "Draft":
                return 'Szkic';
            case "Inactive":
                return 'Nieaktywny';
            case "Active":
                return 'Aktywny';
            case "Finished":
                return 'Zakończony';
            case "Archival":
                return 'Archiwalny';
            case 0:
                return 'w edycji';
            case 1:
                return 'Nieaktywny';
            case 2:
                return 'Aktywny';
            case 3:
                return 'w edycji';
            case 4:
                return 'Zakończony';
            case 5:
                return 'Archiwalny';
            default:
                return 'Nieznany';
        }
    }
}