import {
  Component,
  OnInit,
  Optional,
  Host,
  Input,
  Injector,
  ViewChild,
  ChangeDetectorRef,
  Inject
} from "@angular/core";
import { SatPopover } from "@ncstate/sat-popover";
import {
  PharmacyDto,
  GrouponDeclarationDto,
  API_Pharmacy,
  PagedResultOfPharmacyDto,
  GrouponDeclarationPharmacyDto,
  API_GrouponDeclarations
} from "src/app/services/service-proxies/api";
import { ApplicationComponent } from "../../application.Component";
import { SelectionModel } from "@angular/cdk/collections";
import { HttpErrorResponse } from "@angular/common/http";
import { UserSessionService } from "src/app/services/user-session-service.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DeclaredQuantitiesEditorComponent, QuantityDialogData } from "../declared-quantities-editor/declared-quantities-editor.component";
import { BehaviorSubject } from "rxjs";

export interface DialogData {
  pharmacyId: number
  salesOrganizationId: string
  grouponParticipants: number[]
  declaration: GrouponDeclarationDto
  value: GrouponDeclarationPharmacyDto[]
}

@Component({
  selector: "app-declaration-pharmacies-selector",
  templateUrl: "./declaration-pharmacies-selector.component.html",
  styleUrls: ["./declaration-pharmacies-selector.component.scss"]
})
export class DeclarationPharmaciesSelectorComponent extends ApplicationComponent
  implements OnInit {

  private _modifiedDeclarationPositions: GrouponDeclarationPharmacyDto[];
  private _otherDeclaractions: GrouponDeclarationDto[];

  pharmaciesDataSource: MatTableDataSource<PharmacyDto> = new MatTableDataSource<PharmacyDto>();
  displayedColumns: string[] = ['customerId', 'name', 'houseNumber', 'postalCode', 'locality', 'declared-quantity', 'quantity']; // 'declared-value'

  @ViewChild("pharmacyPaginator", { static: false }) pharmacyPaginator: MatPaginator;

  constructor(injector: Injector, private pharmacyApi: API_Pharmacy, private declarationApi: API_GrouponDeclarations, private session: UserSessionService, private changeDetectorRefs: ChangeDetectorRef, public dialogRef: MatDialogRef<DeclarationPharmaciesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(injector);
  }
  ngOnInit() {

    this._modifiedDeclarationPositions = (this.data.value && [...this.data.value]) || [];

    this.loadPharmacies("", 10000, 0);
  }

  ngAfterViewInit(): void {
  }

  onSubmit() {

    let result = this._modifiedDeclarationPositions.filter(x=> x.declaredPositions.some(p => p.declaredQuantity > 0 )); //.map(x => { return { pharmacyId: x.pharmacyId, declaredPositions: x.declaredPositions } as GrouponDeclarationPharmacyDto; });

    this.dialogRef.close(result);
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  loadPharmacies(searchText: string, pageSize: number, page: number) {
    this.loading(true);

    this.pharmacyApi.get(pageSize, page, searchText).subscribe(
      (pageResult: PagedResultOfPharmacyDto) => {
        this.declarationApi.getAll(this.data.declaration.grouponId, pageResult.data.map(x => x.customerId)).subscribe(result => {
          this.keepOtherDeclarations(result);
          this.setPharmacyDataSource(pageResult.data);
          this.setPaginatorData(pageResult);
          this.loading(false);
        });
      },
      (e: HttpErrorResponse) => {
        this.loading(false);
        throw e;
      }
    );
  }

  private keepOtherDeclarations(declarations: GrouponDeclarationDto[]) {
    this._otherDeclaractions = declarations;
  }

  getDeclaration(customerId: string) {
    return this._otherDeclaractions?.find(x => x.customerId == customerId);
  }

  canModifyDeclaration(customerId: string) {
    let dec = this.getDeclaration(customerId);
    return !dec || dec.salesOrganizationId == this.data.salesOrganizationId;
  }

  private setPharmacyDataSource(data: PharmacyDto[]) {
    if (this.data.grouponParticipants) {
      let currentOrganizationPharmacies = data.filter(x => x.hasRKMHAssigned && this.data.grouponParticipants.includes(parseInt(x.customerId)) && this.canModifyDeclaration(x.customerId) && x.salesOrganizationIds.some(y => y.id === this.data.salesOrganizationId) && x.customerId !== this.data.pharmacyId.toString());
      this.pharmaciesDataSource = new MatTableDataSource<PharmacyDto>(currentOrganizationPharmacies);
    }
  }

  private setPaginatorData(pageResult: PagedResultOfPharmacyDto) {
    this.pharmacyPaginator.length = pageResult.totalCount;
    this.pharmaciesDataSource.paginator = this.pharmacyPaginator;
  }

  getDeclaredQuantities(pharmacyId: string) {
    let dec = this._modifiedDeclarationPositions.find(x => x.pharmacyId == pharmacyId);
    if (dec)
      return dec.declaredPositions;
  }

  getDeclaredQuantitiesSum(pharmacyId: string) {
    let dec = this._modifiedDeclarationPositions.find(x => x.pharmacyId == pharmacyId);
    if (dec && dec.declaredPositions)
      return dec.declaredPositions.reduce((sum, current) => sum + current.declaredQuantity, 0);
  }

  getDeclaredValuesSum(pharmacyId: string) {
    //TODO
    let dec = this.getDeclaration(pharmacyId);
    let decPos = this._modifiedDeclarationPositions.find(x => x.pharmacyId == pharmacyId);
    if (dec && decPos && decPos.declaredPositions)
    return decPos.declaredPositions.reduce((sum, current) => sum + current.declaredQuantity, 0);
  }

  openQuantityDialog(pharmacy: PharmacyDto): void {

    const dialogRef = this.dialog.open<DeclaredQuantitiesEditorComponent, QuantityDialogData>(DeclaredQuantitiesEditorComponent, {
      data: {
        pharmacyId: pharmacy.customerId,
        pharmacyName: pharmacy.name,
        grouponId: this.data.declaration?.grouponId,
        declaredPositions: this.getDeclaredQuantities(pharmacy.customerId),
        salesOrganizationId: this.data.salesOrganizationId
      }
    });

    dialogRef.afterClosed().subscribe((result: GrouponDeclarationPharmacyDto) => {
      if (result) {
        let index = this._modifiedDeclarationPositions.findIndex(x => x.pharmacyId == result.pharmacyId);
        if (index < 0)
          this._modifiedDeclarationPositions.push(result);
        else
          this._modifiedDeclarationPositions[index] = result;
      }
    });
  }
}
