import { Component, OnInit, Injector } from '@angular/core';
import { ApplicationComponent } from '../application.Component';
import { saveAs } from 'file-saver';
import { APP_ROUTES } from '../APP_ROUTES';

@Component({
  selector: 'app-join-rpm',
  templateUrl: './join-rpm.component.html',
  styleUrls: ['./join-rpm.component.scss']
})
export class JoinRpmComponent extends ApplicationComponent implements OnInit {

  redirectToAptelinkOnClose: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(p => {
      this.redirectToAptelinkOnClose = p.hasAnyPharmacy == 'false';     
    });
  }

  downloadBrochure() {
    this.httpClient.get('assets/files/rpm.pdf', {
      observe: 'response',
        responseType: 'blob' // This must be a Blob type
    }).subscribe(res => {
        saveAs()
      //this.fileSaverService.save((<any>res)._body, 'rmp.pdf');
    });
  }

  close() {
    if (this.redirectToAptelinkOnClose) {
      (<any>window).trackerContext.trackEvent('Przekierowano do ' + this.config.AptelinkStarterUrl, {}, {});
      window.open(this.config.AptelinkStarterUrl,"_self");
    }
    else {
      this.router.navigate([`/${APP_ROUTES.selector}`]);
    }
  }
  
}
